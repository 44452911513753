/*optionsGroup*/
.optionsGroup{
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left;
}
.optionsGroupBox{
  padding: 20px;
  padding-top: 0;
  padding-right: 6px;
  /*border: 1px solid #ccc;*/
}
.optionsGroupMainButton {
 cursor: pointer;
  position: relative;
  padding: 12px;
  padding-left: 28px;
  line-height: 1;
  &:before{
    border: 2px solid #ec6612;
    border-left: none;
    border-top: none;
    width: 8px;
    height: 8px;
    content: '';
    display: block;
    position: absolute;
    left: 10px;
    top: 50%;
    transition-duration: .2s;
    transform: translateY(-50%) rotateZ(-45deg);
  }
  &.active:before{
    transform: translateY(-50%) rotateZ(45deg);
  }
}

.groupItem{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  margin: 6px 0;
  box-shadow: 0 0 0 0 #ec6612;
  transition-duration: 0.2s;
  position: relative;
  cursor: pointer;
  &.active{
    border: 1px solid rgba(236, 102, 18, 0.73);
    box-shadow: 0 0 5px 0 #ec6612;
  }
  /*&:after{*/
  /*  content: '';*/
  /*  border-radius: 50%;*/
  /*  background-color: #ccc;*/
  /*  display: block;*/
  /*  width: 10px;*/
  /*  height: 10px;*/
  /*  position: absolute;*/
  /*  right: 10px;*/
  /*  top: 50%;*/
  /*  transition-duration: 0.2s;*/
  /*  transition-property: background-color;*/
  /*  transform: translateY(-50%);*/
  /*}*/
  &.isFilledOut:after{
    background-color: #64bf64;
  }
  &._disabled{
    background-color: #f8f8f8;
    cursor: default;
    color: #959595;
  }
}

.groupItemText{
  padding-left: 10px;
}
/*optionsGroupForm*/
.optionsGroupForm{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  position: relative;
  h6{
    text-transform: none;
    text-align: left;
    color: #717171;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }
}

.optionsGroupFormDialog{
  *{
    margin: 0;
  }
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  h5{
    padding-left: 10px;
  }
}


