.dropzone {
    background: transparent;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
    cursor: pointer;
    height: 58px;
    padding: 10px 10px 10px 15px;
    outline: none;
  }