.gray-background {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.basic-info-card {
  display: flex;
  border-radius: 10px;
  min-height: 100px !important;
  box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
  margin-bottom: 15px !important;
  .multi-col-wrapper {
    max-width: max-content;

    p {
      word-break: break-word;
      font-size: 12px;
  }
}
}

.ag-header {
  background-color: #292929 !important;
  span {
    color: white !important;
  }
}

.participation-badge-font {
  cursor: default;
  font-size: 12px !important;
}

.table {
  thead {
    tr {
      th.text-capitalize {
        text-align: left;
        font-size: 11px;
      }
    }
  }
  tbody {
    tr {
      td {
        p.adjust-table-text {
          width: max-content;
          text-align: left;
        }
      }
    }
  }
}
