
@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

@mixin for-fullhd {
  @media (max-width: 1920px) { @content; }
}

@mixin container-hd {
  @media (min-width: 2560px) { @content; }
}

.active-card-header {
  background-color: greenyellow;
  height: 8px;
  border-radius: 12px 12px 0px 0px;
  margin-bottom: 2rem;
}
.dropdown-wrapper:hover {
  cursor: pointer;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
}

.campaign-card-info-text {
  font-size: 12px;
}
.inactive-card-header {
  background-color: #bbbbbb;
  height: 8px;
  border-radius: 12px 12px 0px 0px;
}
.c-light-gray {
  background-color: #f5f5f5;
}

.c-small-font {
  font-size: .7rem;
}
.c-wide-margin {
  margin: 0rem 16rem;
}
.c-row-header {
  margin: 0rem 16rem;
  padding-left: 18rem;
  margin-top: 250px;

  @include for-phone-only {
    margin: 0px 0px;
    padding-left: 3rem;
  }
}
.c-min-width {
  min-width: 1000px;
  @include for-phone-only {
    min-width: 280px;
  }
}
.c-form-size {
  min-height: 1100px;
  min-width: 300px;

  @include for-phone-only {
    min-height: 637px
  }
}

.c-centered-column {
  width: 60%;

  @include for-phone-only {
    width: 100%;
  }
}

.c-centered-row {
  width: 100%;
  padding: 5rem 0rem;
  @media (max-width: 599px) {
    padding: 0rem 0rem;
  }

  @include for-phone-only {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
}

.c-pill-input {
  width: 160px;
  min-height: 40px;
  margin-left: 0rem;
  border: 0;
  background-color: #f5f5f5;
  transition: 0.3s;

  @include for-phone-only {
    margin-left: 0px;
  }

  &:hover {
    background-color: white;
  }

}

.c-pill-input-button {
  border-radius: $border-radius-btn-small;
  width: 160px;
  min-height: 40px;
  margin-left: 0rem;
  border: 0;
  background-color: #1798DA !important;
}

.c-overlay {
    background-color: rgba(0, 0, 0, 0.55);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
    z-index: 5;
}

.c-slide-modal {
  position: fixed;
  bottom: -150vh;
  background-color: #fff;
  width: 100%;
  left: 0;
  padding: 0 12px 12px;
  transition: bottom 0.3s ease-out;
  z-index: 10;
}

.c-display-card-height {
  height: 17rem;
  width: 32rem;

  @include for-fullhd {
    height: 75%;
  }
}

.c-info-row-height {
  height: 12rem;
  margin-top: 1rem;
  @include for-fullhd {
    margin-bottom: 3rem;
    height: 8rem;
  }
}

.c-filler-bar-width {
  width: 40%;
  @include for-fullhd {
    width: 20%;
  }
  @include for-phone-only {
    width: 0%;
  }
}

.c-phone-flex-wrap {
  @include for-phone-only {
    flex-wrap: wrap;
  }
}

.c-width-14 {
  width: 14%;
  @include for-fullhd {
    width: 20%;
  }
}

.c-camp-info {
  height: 2.3rem;
  @include for-fullhd {
    height: 2rem;

  }
}

.c-font-fullhd {
  @include for-fullhd {
    font-size: .5rem;
  }
}

.c-footer-height {
  height: 24%;
  @include for-fullhd {
    height: 27%;
  }
}

.c-footer-row {
  height: 100%;
}

.c-pill-height {
  height: 10%;
  margin-bottom: 1rem;
  @include for-fullhd {
    margin-bottom: 1rem;
  }
}

.c-footer-hd-height {
  @include for-fullhd {
    height: 50%;
  }
}

.c-1vh-fullhd {
  @include for-fullhd {
    height: 1vh !important;
  }
}

.c-3vh-fullhd {
  @include for-fullhd {
    height: 3vh !important;
  }
}

.c-variable-font {
  font-size: .8rem;
  padding: 5px 10px;
  @include for-fullhd {
    font-size: .5rem;
  }
}

.w-nav {
margin-top:0 !important;
}

.c-navbar {

  width: calc(100% + 1rem);
  background-color: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  //margin-top: 80px;
  margin-bottom:10px;
  min-width:1300px;

}

.c-navcontainer {
  padding: 0;
  // margin-left: 6rem;
  // display: flex;
  height: 100%;
  align-items: center;
}

.c-navblocks {
 // min-width: 215px;
  //height: 100%;
  text-decoration: none;
  text-align: center;
  list-style-type: none;
  // display: flex;
  // justify-content: flex-start;
  align-items: center;
  padding-left:0px;
  color:black;
}

.c-num-icon {
width:20px;
height:20px;
border-radius:10px;
// background-color:blue;
color:white;
}


.c-nav-highlight {
  border-bottom: 5px solid #1588c3;
  // font-weight:700;

}
.step-cotainer {
  margin-right: 200px;
}
@media (max-width: 768px) {
  .step-cotainer {
    margin-right: 35px;
  }
}
.c-camp-header {
  height: 8vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 6rem;
}

.c-form-card {
  width: 1000px;
  background-color: white;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.1);
  padding: 6rem 8rem;
  margin-left: 6rem;
  border-radius: 10px;
}

.c-offer-input {
  height: 160px;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 8px;
  background-color: white;
  border: 2px solid lightgray;
  padding: 66px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.c-offer-select-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.c-offer-radio {
  transform: scale(2);
  display: inline-block;
  margin-right: 40px;
}

.c-input-tag {
  display: inline-block;
}

.c-select-item-active {
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 0px;
  background-color: #f1eefe;
}

.c-select-size {
  height: 35%;
  box-sizing: border-box;
}

.c-offer-type {
  height: 160px;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 8px;
  background-color: white;
  border: 2px solid lightgray;
  padding: 30px 66px;
  display: flex;
  flex-direction: column;
  border-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.c-card-footer {
  height: 160px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.c-form-button-active {
  width: 140px;
  height: 40px;
  background-color: white;
  color: #1898DA;
  border: 1px solid #1898DA;
}
.c-form-button-inactive {
  width: 140px;
  height: 40px;
  background-color: #1898DA;
  color: white;
  border: 1px solid #1898DA;
  opacity: 50%;
}

// .c-content-container {
//   margin: 2rem;
//   margin-left: 2rem;

//   @include container-hd {
//     max-width: 1800px !important;
//   }
// }

.c-button-hover {
  text-decoration: none;
  color:black;
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: black;
  }
}

.c-button-link {
  color: black;
  text-decoration: none;
  font-size: 10px;
  :hover {
    background-color: transparent;
  }
}
.c-button-link-badge {
  color: black;
  text-decoration: none;
  :hover {
    background-color: #cfebfa;
  }
}

.c-badge {
  background-color: #cfebfa;
  color: #1898DA;
  text-align: center;
  width: 20px;
  height: 20px;
}

.c-tabcontent {
  //min-height: 500px;
  // width: 75%;
  // @include container-hd {
  //   width: 55%;
  // }
}

body {
  background-color: #f5f5f5 !important;
}

.c-custom-railcontainer {
  width: 15% !important;
  min-width: 20%;
  position: fixed;
  height: 100vh;
  z-index: 3;

    @media(max-width: 992px){
      display: none;
    }
}

.c-datepicker-override {
  border: 1px solid #DDDDDD !important;
}

.btn.nohover:hover {
  background-color: white !important;
  color: black !important;
  box-shadow: none !important;
  outline: 0;
  transition: none!important;
}
.btn.nohover:focus {
  background-color: white !important;
  color: black !important;
  box-shadow: none !important;
  outline: 0;
  transition: none!important;
}
.btn.nohover:active {
  background-color: white !important;
  color: black !important;
  box-shadow: none !important;
  outline: 0;
  transition: none!important;
}

