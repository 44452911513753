.card-information-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10px;
}

span.text-dark.badge.badge-light {
    background-color: #FFFFFF;
    height: 30px;
    min-width: 81px;
    cursor: pointer;
}

.standard-button-blue {
    background-color: #1798DA !important;
}
