.offerDetailsETW{
  .offerSubmissions{
    display: flex;
    justify-content: space-between;
  }
  .offerSubmissions.offerSubmissions{
    display: flex;
    align-items: center;
    padding-top: 8px;
    h6{
      font-weight: 600;
      font-size: 12px;
      margin: 0;
    }
  }
  .offerWinners{
    header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      h6{
        font-style: italic;
        font-size: 14px;
      }
      .sinnerPeriod{
        width: 200px;
      }
    }
  }
  .winnersTable{
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px;
    padding: 10px;
    tbody{
      tr{
        border-bottom: 1px solid #eaeaea;
      }
      th{
        font-weight: 400;
      }
    }
  }
  .winnersAmount{
    width: 180px;
    display: inline-block;
  }
  .offerWinnersSwitch{
    display: flex;
    div{
      margin: 0 0 0 auto;
    }
  }
  .winnersTableFooter{
    display: flex;
    button{
      display: flex;
      align-items: center;
      margin: 0 0 0 auto;
      svg{
        margin-right: 4px;
      }
    }
  }
}
