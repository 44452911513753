.ETWdetails.ETWdetails.ETWdetails{
  .table {
    margin: 0;
  }
  tr, th {
    padding: 0;
  }
  th {
    padding: 4px 8px;
    text-align: left !important;
  }
  .ETWdetailsRow {
    display: flex;
    margin-bottom: 10px;
    ._true{
      color: green;
    }
    ._false{
      color: #f0512d;
    }
    h6 {
      font-weight: 400;
      white-space: pre;
    }
  }
}
