.qr-codes {
  text-align: left;
  padding-left: 1.25rem;
  width: 50%;
  line-height: 1rem;
}

.email,
.texts {
  text-align: left;
  padding-left: 1.25rem;
  width: 100%;
  line-height: 1rem;
}

.email p,
.texts p {
  font-size: .8rem;
  padding: 8px 0 8px 0;
 
}



/* .button {
  margin: 0 !important;
  float: left;
  display: block;
  height: auto;
} */

.button-qr {
  margin-top: 1rem !important;
  border-radius: 100px !important;
  background-color: rgb(60, 161, 255) !important;
}

.btn-download-qr {
  margin-top: 1rem !important;
  border-radius: 100px !important;
  background-color: rgb(128, 128, 252) !important;
}

.image-qr {
  height: 10rem;
  width: 10rem;
}

.image-multi-qr {
  height: 25rem;
  width: 50rem;
}

.link-entry {
  margin:auto;
}

.social {
  text-align: left;
  font-size: 2rem;
  line-height: 3rem;
  padding-left: 1.25rem;
}

.gen-links:focus, .gen-links:hover, .gen-links:active, .gen-links:visited, .gen-links {
  margin-top: 1rem !important;
  opacity:1 !important;
  border-radius: 100px !important;
  background-color: rgb(60, 60, 255) !important;
}

.gen-links.disabled {
  opacity:0.5 !important;
}


.gmap-locations-list {
  max-height: 350px;
  overflow-y: auto;
}

.multi-qr-content {
  justify-content: center;
}

.offer-search {
  width: 100%;
}

#fileUpload {
  border-radius: 100px;
  background-color: rgb(129, 129, 129);
}

#buildEmail {
  border-radius: 100px;
  background-color: rgb(60, 60, 255);
}

.accordion-button {
  width:100%;
  background-color: transparent;
  border: none;
}

.addLink input {
  width:60%;
  margin:0px;
}