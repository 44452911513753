.cutLongText.cutLongText{
  max-width: 100%;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ._content{
    display: inline;
  }
}
