.textInput.textInput.textInput{
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  input{
    padding: 10px;
  }
  *{
    font-weight: 400;
  }
  label{
    margin: 0;
  }
  &.m0{
    margin: 0;
  }
  &.w50{
    width: 50%;
  }
}

.buttonInput{
  .buttonInputBox{
    position: relative;
    border: 1px solid rgba(204, 204, 204, 0.56);
    border-radius: 6px;
    cursor: pointer;
    padding: 4px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.isSelected{
      border: 1px solid #1798D9;
      &:hover{
        box-shadow: none;
      }
      &:active{
        border: 1px solid rgba(204, 204, 204, 0.56);
        box-shadow: none;
      }
    }
    &:hover{
      box-shadow: 0 0 10px 0 #b8c5cb;
    }
    &:active{
      border: 1px solid #1798D9;
      box-shadow: none;
    }
    .buttonInputText{
      font-size: 16px;
      font-weight: 600;
      margin-left: 20px;
      color: #414141;
    }
    .buttonInputIcon{
      max-height: 80px;
      max-width: 80px;
    }
  }
}

.customRadioInput{
  .customRadioInputBox{
    position: relative;
    border: 1px solid rgba(204, 204, 204, 0.56);
    border-radius: 6px;
    margin-bottom: 5px;
    &.isChecked{
      border: 1px solid #1798D9;
      &:hover{
        box-shadow: none;
      }
      &:active{
        border: 1px solid rgba(204, 204, 204, 0.56);
        box-shadow: none;
      }
    }
    &:hover{
      background: #f1eefe;
      box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
    }
    &:active{
      border: 1px solid #1798D9;
      box-shadow: none;
    }
    .customRadioInputLabel{
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 10px 20px;
    }
    .customRadioInputCheckmark{
      background-color: #ddd;
      border: 3px solid #fff;
      border-radius: 50%;
      transition: all 0.15s;
      position: relative;
      padding: 4px;
      &:after{
        content: '';
        border-radius: 50%;
        display: block;
        height: 12px;
        width: 12px;
      }
    }
    .customRadioInputInput{
      display: none;
      &:checked ~ .customRadioInputCheckmark{
        background-color: #2196f3;
        &:after{
          background: #fff;
        }
      }
    }
    .buttonInputIcon{
      max-height: 80px;
      max-width: 80px;
      margin-left: 10px;
    }
    .buttonInputText{
      margin: auto auto auto 20px;
    }
  }
  .customRadioInputChildrenBox{
    border-top: 1px solid #ededed;
    border-radius: 0 0 6px 6px;
    background-color: #fff;
    padding: 10px 20px;
    width: 100%;
    position: relative;
  }
}
.dateTimePickerBox.dateTimePickerBox.dateTimePickerBox{
  .rdtPicker{
    bottom: 48px;
    th, tr{
      text-align: center;
      border: none;
      &:hover{
        background: transparent;
      }
    }
  }
  &._bottom .rdtPicker{
    bottom: auto;
  }
}
.dateTimePicker {
  border: 1px solid #DDDDDD;
  color: #66615b;
  line-height: normal;
  font-size: 14px;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  box-shadow: none;
  border-radius: 6px;
  height: 41.5px;
  padding: 10px 10px;
  width: 100%;
  &:focus{
    border: 1px solid #9A9A9A;
    box-shadow: none;
    outline: 0 !important;
    color: #66615B;
  }
}

.datePicker.datePicker.datePicker{
  width: 100%;
  position: relative;
  margin: 0;
  input{
    padding: 10px 15px 10px 30px;
    border-radius: 4px;
  }
  input[disabled]{
    background-color: #e6e6e6;
  }
  .datePickerIcon{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    transform: translate(100%, -40%);
    color: #e65b32;
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
  }
}
.switchInput.switchInput{
  label{
    font-weight: bold;
    font-size: 14px;
    padding: 0 10px;
  }
}

.checkboxInput.checkboxInput{
  display: flex;
  align-items: center;
  label{
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    padding: 0 5px;
  }
  input{
    margin: 0;
    position: static;
  }
}
