.rail-container {
  background-color: #414042;
}

#application-rail {
  background-color: #414042;
  background-size: cover;
  padding: 0;
  flex: 0 0 15%;
  max-width: 15%;
  position: relative;
  transition: all 0.25s;
}
#application-rail #toggle-rail {
  display: block;
  height: 20px;
  width: 20px;
  background: #e65b32;
  color: #424242;
  text-align: center;
  line-height: 18px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 14.25%;
  top: 95px;
  z-index: 100;
  border: none;
  transition: all 0.2s;
}
#application-rail #toggle-rail i {
  vertical-align: middle;
}
#application-rail button#toggle-rail.active {
  left: 72px;
}
#application-rail button#toggle-rail.active i {
  transform: rotate(180deg);
}
#application-rail .rail-container {
  padding: 105px 0 30px;
  position: fixed;
  width: 100%;
}
#application-rail .rail-container #toggle-rail {
  display: block;
  height: 20px;
  width: 20px;
  background: #e65b32;
  color: #424242;
  text-align: center;
  line-height: 18px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  position: fixed;
  right: -10px;
  top: 78px;
  z-index: 100;
  border: none;
}
#application-rail .rail-container #toggle-rail i {
  vertical-align: middle;
  transition: all 0.15s;
}
#application-rail .rail-container .customer-logo {
  padding: 0 30px 0px;
  min-height: 40px;
}
#application-rail .rail-container .customer-logo img {
  max-height: 20px;
}
#application-rail .rail-container .customer-logo .customer-logo-shrink {
  display: none;
}
#application-rail .rail-container .section-title {
  padding: 0 30px 15px;
}
#application-rail .rail-container .section-title h1 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
}
#application-rail .rail-container .section-title .title-shrink {
  display: none;
}
#application-rail .rail-container .nav-item:first-child.active .nav-link {
  border-bottom: none;
}
#application-rail .rail-container .nav-submenu {
  padding: 15px 0 0;
  background: rgba(0, 0, 0, 1);
  margin: 0;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
}
#application-rail .rail-container .nav-submenu li {
  list-style: none;
  margin: 0;
  padding: 0;
  background: transparent;
}
#application-rail .rail-container .nav-submenu li > .nav-link-subitem {
  display: block;
  padding: 12px 30px;
  color: #fff;
  opacity: 0.8;
  font-size: 0.8rem;
  margin-bottom: 0 !important;
}
.nav-link-subitem {
  background: transparent;
}

#application-rail .rail-container .nav-submenu li > .nav-link-subitem:hover {
  text-decoration: none;
  background: rgb(80, 78, 80);
}
#application-rail .rail-container .nav-submenu li > .nav-link-subitem.active {
  font-weight: 700 !important;
  letter-spacing: 0.5px;
  opacity: 1;
}
#application-rail .rail-container button#toggle-rail.active i {
  transform: rotate(180deg);
}
#application-rail .rail-container .nav {
  transition: all 0.25s;
}
#application-rail .rail-container .nav .nav-link {
  font-size: 0.8rem;
  color: #fff;
  padding: 20px 30px;
  transition: all 0.25s;
  letter-spacing: 1px;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
}
#application-rail .rail-container .nav .nav-link i {
  opacity: 0.5;
  transition: all 0.15s;
  margin-right: 5px;
  font-size: 0.95rem;
  vertical-align: middle;
}
#application-rail .rail-container .nav .nav-link span {
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
#application-rail .rail-container .nav .nav-link:hover {
  background: #292829;
}
#application-rail .rail-container .nav .nav-link:hover i {
  opacity: 1;
  color: #7ac663;
}

#application-rail .rail-container .nav .nav-item.a6-item .nav-link:hover {
  background: #292829;
}
#application-rail .rail-container .nav .nav-item.a6-item:last-child .nav-link {
  border-bottom: none;
}

#application-rail .rail-container .nav .nav-item.a6-item .nav-link.active {
  background: #1898da;
  color: #fff;
  font-weight: bold;
}
.blue {
  background: #1898da !important;
}
#application-rail .rail-container .nav .nav-item .nav-link.active:hover i {
  color: #0b4867;
}
#application-rail .rail-container .nav .dropdown-menu.show {
  position: relative !important;
  left: auto !important;
  right: auto !important;
  top: auto !important;
  bottom: auto !important;
  transform: none !important;
}
#application-rail .rail-container .nav .dropdown-menu {
  background: #292829;
  border-radius: 0;
  min-width: inherit;
  padding: 0 !important;
  float: none;
}
#application-rail .rail-container .nav .dropdown-menu > .nav-link {
  font-size: 0.8rem;
  color: #fff;
  transition: all 0.25s;
  padding-top: 15px;
  padding-bottom: 15px;
}
#application-rail .rail-container .nav .dropdown-menu > .nav-link:hover {
  background: #7ac663;
}
#application-rail.shrink {
  flex: 0 0 80px;
  max-width: 80px;
}
#application-rail.shrink .nav-link span {
  width: 0;
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
  visibility: hidden;
  display: none;
}
#application-rail.shrink .nav-link i {
  font-size: 1.25rem;
}
#application-rail.shrink .section-title {
  padding: 0 15px 15px;
}
#application-rail.shrink .customer-logo-full,
#application-rail.shrink .title-full {
  display: none;
}
#application-rail.shrink .customer-logo-shrink,
#application-rail.shrink .title-shrink {
  display: block !important;
}

#application-rail {
  padding: 0;
  flex: 0 0 85%;
  max-width: 85%;
  transition: all 0.25s;
}
#application-rail .container {
  padding: 0 1rem;
}
#application-rail #platformContent {
  margin-top: 65px;
}
#application-rail .tab-content {
  margin-top: 45px;
}
#application-rail .tab-content .col-md-4 .card {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
#application-rail .tab-content .col-md-4:nth-child(2) .card {
  animation-delay: 0.1s;
}
#application-rail .tab-content .col-md-4:nth-child(3) .card {
  animation-delay: 0.15s;
}
#application-rail .tab-content .col-md-4:nth-child(4) .card {
  animation-delay: 0.2s;
}
#application-rail .col-sm-4 .profile-card {
  overflow: visible;
}
#application-rail .col-sm-4 .profile-card p {
  font-size: 0.8rem;
}
#application-rail .platform-navigation {
  position: fixed;
  right: 0;
  left: 15%;
  top: 54px;
  z-index: 10;
  transition: all 0.25s;
}
#application-rail .platform-navigation .btn {
  position: absolute;
  right: 50px;
  top: 10px;
  font-size: 0.75rem;
  padding-left: 20px;
  padding-right: 20px;
}
#application-rail .platform-navigation .btn i {
  margin-right: 5px;
  color: #1898da;
  opacity: 0.75;
}
#application-rail .platform-navigation .btn:hover {
  color: #aada9b;
  text-decoration: none;
}
#application-rail .platform-navigation .btn:hover i {
  opacity: 1;
}
#application-rail .user-card-header .user-avatar {
  margin-bottom: 10px;
  margin-top: -55px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
}
#application-rail .user-card-header .user-avatar img {
  max-height: 75px;
  border-radius: 50%;
  border: 3px solid #fff;
}
#application-rail .user-card-header h3 {
  padding-bottom: 5px;
}
#application-rail .user-card-header p.user-title {
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  color: #999;
}
#application-rail .user-card-header:after {
  content: "";
  display: block;
  width: 50px;
  height: 5px;
  background: #7ac663;
  margin: 15px 0;
  opacity: 0.5;
}
#application-rail.full-width {
  flex: 0 0 calc(100% - 80px);
  max-width: calc(100% - 80px);
}
#application-rail.full-width .platform-navigation {
  left: 80px;
}

@media (min-width: 1200px) {
  .A6-dashboard .container {
    max-width: 1180px;
  }
}
@media (min-width: 1600px) {
  .A6-dashboard .container {
    max-width: 1480px;
  }
  .A6-dashboard #burn-chart {
    width: 23% !important;
  }
}
@media (min-width: 767px) {
  .bootstrap-timepicker-widget.modal {
    width: 200px;
    margin-left: -100px;
  }
}
@media (max-width: 767px) {
  .bootstrap-timepicker {
    width: 100%;
  }
  .bootstrap-timepicker .dropdown-menu {
    width: 100%;
  }
}
