.flip-container-wrap{
  width: 100%;
  position: relative;
  min-height: 100%;
  .flip-button{
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 30%);
    z-index: 1;
    cursor: pointer;
    &:hover{
      transition-duration: 0.1s;
      transform: translate(-50%, 30%) scale(1.1);
    }
  }
}
.flip-container {
  perspective: 1000px;
  position: relative;
  width: 100%;
  height: 100%;
}
.flipper {
  transition: 0.5s;
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.flip-container.otherSide .flipper {
  transform: rotateY(180deg);
}

.front, .back {
  backface-visibility: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.front {
  z-index: 2;
}
.back {
  transform: rotateY(180deg);
}
