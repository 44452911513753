.designerWrapper{
  padding: 0;
}
.designerWrapperTitle{
  color: #717171;
  font-size: 12px;
  text-align: left;
}
.animatedBox{
  & > *{
    animation-delay: .1s;
    animation-name: fadeInUp;
    animation-duration: 0.75s;
     animation-fill-mode: both;
  }
  @for $i from 1 through 20 {
    & > *:nth-child(#{$i}){
      animation-delay: #{$i/10}s;
    }
  }
}

