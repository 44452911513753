.logoField.logoField {
  label{
    display: block;
    margin: 0;
    cursor: pointer;
  }
  .preview {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: rgba(204, 204, 204, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    height: 108px;
    &:after{
      content: '+';
      font-size: 40px;
      line-height: 0.5;
      position: absolute;
      left: 4px;
      top: 6px;
      display: block;
      width: 34px;
      height: 34px;
      opacity: 0.2;
    }
    img, svg{
      max-height: 100%;
      max-width: 100%;
    }
    svg{
      opacity: 0.2;
    }
  }
  .inputBox{
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    input{
      border: none;
    }
    &>div{
      border-left: 1px solid #ddd;
      padding: 2px;
      cursor: pointer;
      svg{
        opacity: .5;
      }
    }
  }
  .hint{
    margin-top: 0px;
    font-size: 10px;
    color: rgba(42, 42, 42, 0.59);
    font-weight: 400;
    font-style: italic;
  }
}

/////// pdfField
.pdfField{
  margin-bottom: 10px;
  .label{
    font-size: 0.8571em;
    margin-bottom: 5px;
  }
  .pdfFieldBox{
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    display: flex;
    .form-control{
      border: none;
    }
  }
  .pdfFieldLabel{
    border-left: 1px solid #ccc;
    cursor: pointer;
    width: 50px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      opacity: 0.5;
    }
  }
}

//////// textColorInput
.textColorInput.textColorInput{
  label{
    margin-bottom: 0;
  }
  .box{
    display: flex;
    position: relative;
    padding-left: 40px;
    margin-bottom: 10px;
    .preview{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      display: block;
      width: 31px;
      cursor: pointer;
    }
  }
}

//EditorHtml
.tox.tox-tinymce-inline.tox.tox-tinymce-inline{
  transform: translate(-8px, -8px);
  .tox-editor-header{
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.38);
  }
}
.tox.tox-tinymce-aux .tox-toolbar__overflow{
  transform: translate(-2px, 8px);
}
.EditorHtml{
  .EditorHtmlBox{
    position: relative;
    .button{
      border: 1px solid #ccc;
      border-radius: 4px;
      position: absolute;
      right: 3px;
      top: 3px;
      width: 20px;
      height: 20px;
      padding: 2px;
      cursor: pointer;
      z-index: 2;
      display: block;
      &>svg{
        display: block;
      }
    }
    .fieldBox{
      margin-bottom: 10px;
    }
    .field{
      display: flex;
      .mce-content-body{
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        padding: 4px 8px;
        min-height: 100%;
        width: 100%;
      }
      p{
        margin: 0;
      }
    }
  }
  .sourceCode{
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    resize: none;
  }
  &.isOpen{
    background-color: rgba(0, 0, 0, 0.37);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    .EditorHtmlBox{
      position: absolute;
      width: 700px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .button{
        display: none;
      }
    }
  }
}

//switchField
.switchField.switchField.switchField{
  display: flex;
  justify-content: space-between;
  &.margin0 .bootstrap-switch{
    margin: 0;
  }
}

.linkField{
  .linkFieldBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    .marginR10{
      margin-right: 10px;
    }
    .inputText{
      flex: 1;
    }
  }
}
.marginButton10{
  margin-bottom: 10px;
}
