.paypal-account-content {
  .card {
    margin: 0 50px;
        input {
            width: 80%;
        }
  }

  .settings-wrapper {
    margin-left: 40px;
  }
}