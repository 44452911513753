.proofOfPurchase{
  width: 100%;
  margin-top: 20px;
  .proofOfPurchaseTitle{
    font-size: 14px;
    color: #2c2c2c;
    font-weight: 600;
  }
  .proofOfPurchaseBox{
    display: flex;
    margin: 0;
    margin-top: 10px;
    .proofOfPurchaseLeft{
      width: 100%;
      transition: width 0.2s;
    }
    .proofOfPurchaseRight{
      width: 0;
      height: 0;
      overflow: hidden;
      transition: width 0.5s;
    }
    &.isSelected{
      .proofOfPurchaseLeft{
        width: 50%;
      }
      .proofOfPurchaseRight{
        width: 50%;
        height: auto;
        overflow: visible;
      }
    }
  }
}
.proofDetailsWrap{
  padding: 0 10px;
  .proofDetails{
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 100%;
    padding: 10px;
    padding-top: 5px;
    .proofDetailsTitle{
      font-size: 14px;
      font-weight: 600;
      color: rgba(42, 42, 42, 0.59);
      text-align: center;
    }
    .proofDetailsBox{
      // padding: 10px;
    }
    .proofDetailsFooter{
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }
    button{
      margin: 0;
    }
    .dateInfo{
      .separator{
        display: block;
        height: 5px;
      }
    }
  }
}

.rdt.rdt.rdt{
  &.right0{
    .rdtPicker{
      right: 0;
      left: auto;
    }
  }
  &.left0{
    .rdtPicker{
      left: 0;
      right: auto;
    }
  }
}
.offerTiming{
  h5{
    color: #252422;
    font-weight: 400;
    font-size: 14px;
  }
}
