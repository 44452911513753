/*Brand Colors */

body,
html {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

a {
  color: #1898da;
  transition: all 0.1s;
}
a:hover {
  color: #106895;
}

h3 {
  font-size: 20px;
  margin-bottom: 30px;
}

h4.component-title {
  font-size: 14px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;
}
h4.component-title > a {
  display: block;
}

h4.sub-header,
h5.sub-header {
  font-size: 15px;
  margin-bottom: 30px;
  font-weight: 300;
}

h4.form-header {
  font-size: 16px;
  font-weight: 600;
  color: #5632ee;
  margin-bottom: 15px;
}

.text-primary {
  color: #1898da !important;
}

.text-orange {
  color: #f0512d !important;
}

.text-secondary {
  color: #b78b20 !important;
}

span.text-primary{
  color: #000 !important;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.15px;
}

h3.text-primary {
  color: #000 !important;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.15px;
  margin-bottom: 25px;
}
h3.text-primary span {
  color: #1898da !important;
  opacity: 0.5;
}
h3.text-primary span.slider {
  opacity: 1;
}
h3.text-primary .badge {
  opacity: 1;
  color: #fff !important;
  font-size: 11px !important;
}
.card .btn-group .badge {
  box-shadow: 0 0 1px grey;
}

.form-row i.zmdi-help {
  margin-left: 10px !important;
  color: #5ac8fa;
  font-size: 1.1rem;
  vertical-align: middle;
  cursor: pointer;
}
.form-row i.zmdi-help:hover {
  color: #29b8f9;
}

.bg-grey {
  background: #414042;
}

.badge {
  font-weight: 400;
  background: #fff;
  vertical-align: middle;
  font-size: 70%;
}
.badge span {
  display: block;
  float: left;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  margin-right: 10px;
}

.badge-success.activity-badge {
  color: #000;
  font-weight: 500;
  background: #a0ed68 !important;
  font-size: 11px;
  margin-top: -3px;
  padding: 8px 12px;
}
.badge-success.activity-badge span {
  background: #53a714 !important;
  border: 1px solid #fff;
  opacity: 1 !important;
  display: none;
}
.badge-success.activity-badge i {
  font-size: 0.9rem;
  line-height: 0.5rem;
  vertical-align: middle;
  color: #53a714 !important;
}

.badge-danger {
  background: #92ea51 !important;
}

.badge-danger {
  background: #ea5151 !important;
}

.badge-warning {
  background: #ead251 !important;
}

.badge-primary {
  background: #1898da !important;
}

.badge-purple {
  opacity: 1 !important;
  background: #5632ee !important;
  color: #fff !important;
}

.card .badge {
  border: none !important;
}

.cover-container {
  max-width: 42em;
}

.height-100vh {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  border: none;
  border-radius: 20px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
}

.btn-lg {
  font-size: 1rem;
  padding: 1.25rem 2.5rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.btn-sm {
  font-size: 0.65rem;
  padding: 0.25rem 0.25rem;
}
.btn-sm i {
  font-size: 0.9rem;
  margin-right: 3px;
  vertical-align: middle;
}

.btn-primary {
  background: #1898da;
  padding-left: 30px;
  padding-right: 30px;
}
.btn-primary:hover {
  background: #106895;
}

.btn-secondary {
  background: #5632ee;
  padding-left: 30px;
  padding-right: 30px;
}
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  background: #3210c3;
}

.btn-outline-primary {
  border: 1px solid #1898da;
  color: #1898da;
}
.btn-outline-primary i {
  color: #52b8ec;
}
.btn-outline-primary:hover {
  background: #1588c3;
  border-color: #1588c3;
}

.btn-outline-muted {
  border: 1px solid #ddd;
  color: #666;
  border-radius: 4px;
}
.btn-outline-muted i {
  color: #1898da;
  margin-right: 15px;
}
.btn-outline-muted:hover {
  background: #d0d0d0;
  border-color: #d0d0d0;
}

.btn-link {
  color: #1898da;
}
.btn-link:hover {
  color: #1378ac;
}

.step-container {
  /*margin-right: 200px;*/
}
@media (max-width: 768px) {
  .step-container {
    margin-right: 35px;
  }
}

.navbar {
  background: #292829;
  color: #fff;
  min-height: 52px;
}
.navbar .navbar-brand {
  position: relative;
}
.navbar .navbar-brand img {
  position: absolute;
  top: -23px;
  max-width: 75px;
  height: 75px;
}
.navbar .dropdown-toggle {
  position: relative;
}
.navbar .dropdown-toggle::after {
  content: "\f2f9";
  font: normal normal normal 14px/1 "Material-Design-Iconic-Font";
  border: none;
  vertical-align: middle;
}
.navbar .dropdown-menu {
  left: auto;
  background: #1b1a1b;
  border-radius: 0;
  border: none;
  margin-top: 8px;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar .dropdown-menu .dropdown-item {
  color: #fff;
  font-size: 0.75em;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.15s;
  /*border-bottom:1px dotted rgba(255,255,255,0.25);*/
}
.navbar .dropdown-menu .dropdown-item:hover {
  background: #292829;
}
.navbar .dropdown-menu .dropdown-item:last-child {
  border: none;
}
.navbar .dropdown-menu .dropdown-search {
  padding: 0.5rem 0.75rem 0.35rem;
  position: relative;
}
.navbar .dropdown-menu .dropdown-search input.form-control {
  font-size: 0.7rem;
}
.navbar .dropdown-menu .dropdown-search:after {
  content: "\f1c3";
  display: inline-block;
  font: normal normal normal 14px/1 "Material-Design-Iconic-Font";
  color: #ccc;
  position: absolute;
  right: 23px;
  top: 17px;
}
.navbar .nav-item {
  position: relative;
}
.navbar .nav-button:after {
  content: "";
  display: block;
  border: 1px solid #1898da;
  background: #1898da;
  position: absolute;
  top: 3px;
  bottom: 8px;
  left: 10px;
  right: 10px;
  opacity: 1;
  z-index: -1;
  border-radius: 20px;
}
.navbar .nav-button .nav-link i {
  color: #fff;
}
.navbar .nav-button:hover:after {
  background: #1588c3;
}
.navbar .nav-button:hover .nav-link {
  color: #fff !important;
}
.navbar .nav-link {
  color: #fff !important;
  transition: all 0.15s;
  font-size: 0.75rem;
}
.navbar .nav-link i {
  color: #1898da;
  margin-right: 3px;
  vertical-align: middle;
}
.navbar .nav-link i.zmdi-plus {
  font-size: 0.85rem !important;
  line-height: 0.5rem;
  vertical-align: middle !important;
}
.navbar .nav-link i.zmdi-notifications {
  font-size: 1.5rem !important;
  line-height: 1rem;
  color: #fff;
}
.navbar .nav-link .user-icon {
  display: inline-block;
  height: 22px;
  width: 22px;
  background-size: contain;
  vertical-align: middle;
  margin-right: 5px;
}
.navbar .nav-link:hover {
  color: #bbb !important;
}
.navbar .nav-link:hover i {
  color: #f1c32c !important;
}
.navbar .notification-dropdown {
  min-width: 250px;
  right: -25px;
  left: auto;
  padding-bottom: 0;
}
.navbar .notification-dropdown strong {
  font-size: 0.8rem;
}
.navbar .notification-dropdown p {
  font-size: 0.7rem;
  margin: 0;
}
.navbar .notification-dropdown small {
  font-size: 8px;
  margin-left: 5px;
  margin-top: 5px;
}
.navbar .notification-dropdown .dropdown-item {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.2);
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.navbar .notification-dropdown .dropdown-item:last-child {
  border-bottom: none !important;
}
.navbar .notification-dropdown .btn-all {
  font-size: 0.8rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.navbar .notification-dropdown .btn-all i {
  float: right;
  vertical-align: middle;
  transition: all 0.1s;
  font-size: 1.15rem;
  margin-top: 2px;
  color: #f0512d;
}
.navbar .notification-dropdown .btn-all:hover i {
  margin-left: -5px;
}
.navbar .nav-item:nth-child(1) i,
.navbar .nav-item:nth-child(3) i {
  font-size: 1.1rem;
  margin-right: 3px;
  vertical-align: middle;
}
.navbar .nav-item:nth-child(2) i {
  font-size: 1.2rem;
  margin-right: 3px;
  vertical-align: middle;
}
.navbar .nav-item:nth-child(1),
.navbar .nav-item:nth-child(2),
.navbar .nav-item:nth-child(3) {
  margin-left: 0;
}
.navbar .nav-item:nth-child(1) i,
.navbar .nav-item:nth-child(2) i,
.navbar .nav-item:nth-child(3) i {
  transition: all 0.3s;
}
.navbar .nav-item:nth-child(1) .nav-link,
.navbar .nav-item:nth-child(2) .nav-link,
.navbar .nav-item:nth-child(3) .nav-link {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.navbar .nav-item:nth-child(1) .nav-link:hover i,
.navbar .nav-item:nth-child(2) .nav-link:hover i,
.navbar .nav-item:nth-child(3) .nav-link:hover i {
  color: #1898da;
}

#A6-login {
  max-width: 500px;
  padding: 25px 50px !important;
}
#A6-login .form-group {
  text-align: left;
}
#A6-login .form-group label {
  color: #fff;
}
#A6-login .form-group .form-control {
  background: transparent;
  color: #fff;
  padding: 0.5rem 1rem;
  height: calc(1.5em + 1.5rem + 2px);
  border-radius: 0.5rem;
}
#A6-login .btn-row {
  text-align: right;
  padding-top: 10px;
}

.A6-dashboard-page {
  background: #f5f5f5;
}

.platform-header {
  padding: 45px 0 15px;
  overflow: hidden;
  position: relative;
  background: #414042;
}
.platform-header h1 {
  color: #fff;
  position: relative;
  z-index: 10;
  font-weight: 300;
  font-size: 1.25rem;
}

#qr-map {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 3px;
  padding: 3px;
}

.qr-filter .input-group .input-group-addon {
  position: absolute;
  z-index: 10;
  left: 8px;
}
.qr-filter .input-group .input-group-addon i {
  font-size: 1.25rem;
  line-height: 1rem;
  color: #ccc;
  padding: 10px 0;
}
.qr-filter .input-group .form-control {
  padding-left: 30px;
  border-radius: 4px;
  border-right: none !important;
  font-size: 0.8rem;
}
.qr-filter .input-group .btn-outline-muted {
  border: 1px solid #ddd;
  padding-top: 0.23rem !important;
  padding-bottom: 0.23rem !important;
  border-top-right-radius: 4px !important;
  border-left: 1px dashed #ddd;
  border-bottom-right-radius: 4px !important;
}
.qr-filter .input-group .btn-outline-muted:hover {
  background: #f1f1f1;
}
.qr-filter .qr-scroll-list {
  max-height: 450px;
  overflow-y: auto;
}
.qr-filter .qr-scroll-list li {
  font-size: 0.75rem;
  padding: 15px 5px;
  border-bottom: 1px dotted #ddd;
}
.qr-filter .qr-scroll-list li p {
  margin-bottom: 5px;
}
.qr-filter .qr-scroll-list li.active {
  background: white;
}
.qr-filter .qr-scroll-list .business-name {
  font-size: 14px;
  margin-bottom: 5px !important;
}
.qr-filter .qr-scroll-list .business-select {
  color: #7ac663;
  transition: all 0.15s;
  display: block;
  font-size: 0.7rem;
  margin-bottom: 0px;
}
.qr-filter .qr-scroll-list .business-select input[type="checkbox"] {
  margin-right: 3px;
  vertical-align: middle;
}
.qr-filter .qr-scroll-list .business-select:hover {
  color: #0e7275;
}

.progress-step-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px dotted #ddd;
  margin: 10px 0 20px;
}
.progress-step-wrapper > .progress-step {
  width: 50%;
  font-size: 0.7rem;
  text-align: center;
  position: relative;
  color: #666;
}
.progress-step-wrapper > .progress-step:before {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #bbb;
  position: absolute;
  top: -25px;
  left: 50%;
  margin-left: -10px;
}
.progress-step-wrapper .progress-step.active {
  color: #0e7275;
  font-weight: bold;
}
.progress-step-wrapper .progress-step.active:before {
  background: #fff;
  border-color: #7ac663;
}
.progress-step-wrapper .progress-step.complete {
  color: #0e7275;
  font-weight: bold;
}
.progress-step-wrapper .progress-step.complete:before {
  background: #7ac663 !important;
  border-color: #7ac663;
}
.progress-step-wrapper:before {
  content: "";
  display: block;
  height: 5px;
  width: 50%;
  background: #eee;
  position: absolute;
  left: 25%;
  right: 25%;
  top: -17.5px;
}

.nav-tabs .nav-item {
  margin-right: 2px;
}
.nav-tabs .nav-item .nav-link {
  font-size: 1rem;
  padding: 0.65rem 45px;
  color: #424242;
  transition: all 0.15s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.nav-tabs .nav-item .nav-link .badge {
  padding: 0.2rem 0.35rem;
  border-radius: 15px;
  font-weight: bold;
  vertical-align: middle;
}
.nav-tabs .nav-item .nav-link:hover {
  background: rgba(255, 255, 255, 0.5);
  color: #1898da;
}
.nav-tabs .nav-item .nav-link.active {
  font-weight: bold;
  color: #000;
}
.nav-tabs .nav-item .nav-link.active:hover {
  background: #fff;
}

.campaign-single-select {
  margin: 0;
  padding: 0;
  width: 100%;
}
.campaign-single-select li {
  margin: 0;
  padding: 0;
  list-style: none;
  clear: both;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  overflow: hidden;
  border-radius: 6px;
}
.campaign-single-select li .select-item {
  padding: 5px 25px;
  font-weight: 400;
  display: flex;
  font-size: 0.9rem;
  flex-direction: row;
  align-items: center;
  transition: all 0.25s;
  width: 100%;
}
.campaign-single-select li .custom-radio {
  float: left;
  margin: 0 !important;
  margin-right: 20px !important;
}
.campaign-single-select li img {
  max-height: 100px;
  margin-left: 15px;
  mix-blend-mode: multiply;
  margin-left: auto;
}
.campaign-single-select li .select-item:hover {
  background: #f1eefe;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}
.campaign-single-select li .form-row {
  padding-top: 10px;
  padding-bottom: 10px;
}
.campaign-single-select li .alert-muted {
  background: #fff;
  text-align: center;
  margin: 10px 0 0;
  border-radius: 6px;
  background: #fbfaff;
  border: 1px dotted #cabffa !important;
}
.campaign-single-select li .alert-muted p {
  color: #5632ee;
}
.campaign-single-select li p em.small {
  font-size: 0.7rem;
  padding: 0.25rem 0 !important;
  color: #999;
}
.campaign-single-select li:hover {
  border-color: #b7a8f8;
}
.campaign-single-select li .select-item.active {
  background: #f1eefe;
  border-color: #5632ee;
}
.campaign-single-select li.active {
  border-color: #b7a8f8;
}
.campaign-single-select li.active .select-item {
  background: #f1eefe;
}
.campaign-single-select .select-item-options {
  padding: 20px 25px;
  background: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  text-align: left;
  width: 100%;
}
.campaign-single-select .select-item-options label {
  margin-bottom: 10px;
  font-size: 0.8rem;
  font-weight: 500;
  justify-content: flex-start;
}
.campaign-single-select .select-item-options .form-control {
  font-size: 0.85rem;
  padding: 0.5rem 1rem;
  height: calc(2em + 0.75rem + 2px);
}

.custom-checkbox {
  position: relative;
  height: 25px;
  width: 25px;
  margin: 0 !important;
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-checkbox .checkmark {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 25px;
  width: 25px;
  background-color: #ddd;
  border-radius: 6px;
  transition: all 0.15s;
}
.custom-checkbox:hover input ~ .checkmark {
  background-color: #bbb;
}
.custom-checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
}
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.custom-checkbox .checkmark:after {
  left: 9px;
  top: 6px;
  width: 5px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-radio {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 25px;
  width: 25px;
  margin-bottom: 0;
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-radio .checkmark {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 25px;
  width: 25px;
  background-color: #ddd;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all 0.15s;
}
.custom-radio:hover input ~ .checkmark {
  background-color: #bbb;
}
.custom-radio input:checked ~ .checkmark {
  background-color: #2196f3;
}
.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-radio input:checked ~ .checkmark:after {
  display: block;
  background: #fff;
}
.custom-radio .checkmark:after {
  left: 4.5px;
  top: 4.5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.custom-checkbox.selected {
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
.custom-checkbox.selected input:checked ~ .checkmark {
  background-color: #2196f3;
}
.custom-checkbox.selected .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-checkbox.selected input:checked ~ .checkmark:after {
  display: block;
}
.custom-checkbox.selected .checkmark:after {
  left: 7px;
  top: 11px;
  width: 11px;
  height: 3px;
  background: white;
  transform: none !important;
}

i.zmdi-info {
  font-size: 0.9rem;
  color: #5ac8fa;
  line-height: 0.5rem;
  margin-left: 5px;
  vertical-align: middle;
}

.trigger-list .trigger-item {
  display: flex;
  flex-direction: row;
  padding: 15px;
  border-bottom: 1px dotted #ddd;
  justify-content: space-between;
  transition: all 0.15s;
}
.trigger-list .trigger-item a {
  font-size: 0.75rem;
  line-height: 75px;
}
.trigger-list .trigger-item a i {
  margin-right: 5px;
}
.trigger-list .trigger-item a:hover {
  text-decoration: none;
  color: #424242;
}
.trigger-list .trigger-item .qr-container {
  width: 75px;
  padding: 10px;
  border-radius: 6px;
  background: #fff;
  border: 1px solid #eee;
  text-align: center;
}
.trigger-list .trigger-item .qr-container a {
  font-size: 0.65rem;
  padding-top: 15px;
}
.trigger-list .trigger-item .qr-container a i {
  margin-right: 3px;
}
.trigger-list .trigger-item .qr-container img {
  width: 100%;
}
.trigger-list .trigger-item .trigger-info,
.trigger-list .trigger-item .trigger-input {
  display: flex;
  flex-direction: column;
}
.trigger-list .trigger-item .trigger-info label,
.trigger-list .trigger-item .trigger-input label {
  font-size: 0.8rem;
}
.trigger-list .trigger-item .trigger-info input.form-control,
.trigger-list .trigger-item .trigger-input input.form-control {
  font-size: 0.8rem;
}
.trigger-list .trigger-item .trigger-info input[type="url"],
.trigger-list .trigger-item .trigger-input input[type="url"] {
  min-width: 280px;
}
.trigger-list .trigger-item .trigger-input {
  width: 260px;
}
.trigger-list .trigger-item .trigger-input .form-control {
  border-radius: 4px !important;
  font-size: 0.75rem;
  line-height: 1.3rem;
  min-height: 90px !important;
}
.trigger-list .trigger-item:hover {
  background: #f9f9f9;
}
.trigger-list .trigger-add {
  padding: 15px 0 0;
}
.trigger-list .trigger-add .btn-link {
  font-size: 0.8rem;
}
.trigger-list .trigger-add .btn-link i {
  margin-right: 5px;
}

.trigger-list.qr-code .trigger-item {
  justify-content: flex-start;
  align-items: center;
}
.trigger-list.qr-code .trigger-item .trigger-info {
  margin-left: 25px;
}
.trigger-list.qr-code .trigger-item .trigger-actions {
  margin-left: auto;
  padding: 20px 30px;
  border-radius: 10px;
  background: #f7f7f7;
}
.trigger-list.qr-code .trigger-item .trigger-actions a {
  font-size: 0.8rem;
  display: block;
  line-height: 1rem;
  margin-bottom: 15px;
}
.trigger-list.qr-code .trigger-item .trigger-actions a i {
  color: #69c1ef;
}
.trigger-list.qr-code .trigger-item .trigger-actions a:last-child {
  margin-bottom: 0;
}
.trigger-list.qr-code .trigger-item:hover {
  background: #fff;
}
.trigger-list.qr-code .qr-container {
  width: 150px;
}

.trigger-list.mobile-list label.custom-checkbox {
  margin-right: 40px !important;
  margin-top: -2px;
}
.trigger-list.mobile-list .trigger-item {
  justify-content: flex-start;
  font-size: 0.9rem;
  color: #1378ac;
  cursor: pointer;
}

.platform-navigation {
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.platform-navigation .nav-pills .nav-item {
  margin-right: 50px;
}
.platform-navigation .nav-pills .nav-link {
  border-radius: 0;
  border-bottom: 5px solid transparent;
  padding: 15px 0 10px;
  color: #424242;
  font-size: 0.8rem;
}
.platform-navigation .nav-pills .nav-link span {
  margin-right: 5px;
  color: #7ac663;
  display: inline-block;
  text-align: center;
  font-weight: 900;
  border-radius: 4px;
  line-height: 18px;
  vertical-align: middle;
  width: 20px;
  background: #7ac663;
  font-size: 0.7rem;
  color: #fff;
}
.platform-navigation .nav-pills .nav-link.disabled span {
  background: #f4f4f4;
  font-size: 0.7rem;
  color: #999;
}
.platform-navigation .nav-pills .nav-link.active {
  font-weight: 700;
  background: #fff;
  border-color: #7ac663;
}
.platform-navigation .nav-pills .nav-link.active span {
  color: #7ac663;
  background: #e9f6e6;
}
.platform-navigation .nav-pills .nav-link.disabled {
  color: #999;
}
.platform-navigation .nav-pills .nav-item:first-child .complete span {
  background: #1588c3;
}
.platform-navigation .nav-pills .nav-item:first-child .nav-link.active {
  font-weight: 700;
  background: #fff;
  border-color: #1588c3;
}
.platform-navigation .nav-pills .nav-item:first-child .nav-link.active span {
  color: #1898da;
  background: #dcf1fb;
}
.platform-navigation .nav-pills .nav-item:nth-child(2) .complete span {
  background: #e65b32;
}
.platform-navigation .nav-pills .nav-item:nth-child(2) .nav-link.active {
  font-weight: 700;
  background: #fff;
  border-color: #e65b32;
}
.platform-navigation .nav-pills .nav-item:nth-child(2) .nav-link.active span {
  color: #e65b32;
  background: #fcece8;
}
.platform-navigation .nav-pills .nav-item:nth-child(4) .complete span {
  background: #5632ee;
}
.platform-navigation .nav-pills .nav-item:nth-child(4) .nav-link.active {
  font-weight: 700;
  background: #fff;
  border-color: #5632ee;
}
.platform-navigation .nav-pills .nav-item:nth-child(4) .nav-link.active span {
  color: #5632ee;
  background: #f1eefe;
}
.platform-navigation .nav-pills .nav-item:nth-child(5) .complete span {
  background: #5ac8fa;
}
.platform-navigation .nav-pills .nav-item:nth-child(5) .nav-link.active {
  font-weight: 700;
  background: #fff;
  border-color: #5ac8fa;
}
.platform-navigation .nav-pills .nav-item:nth-child(5) .nav-link.active span {
  color: #5ac8fa;
  background: #e0f5fe;
}

p.form-help {
  font-size: 0.8rem;
}

#cp2 input.form-control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding-left: 1rem;
  max-width: 100px;
}
#cp2 .input-group-append {
  border-left: none;
}
#cp2 .input-group-text {
  background: #fff;
  transition: all 0.25s;
  border-left: none;
}
#cp2 .input-group-text:hover {
  background: #f7f7f7;
}

form h2 {
  font-size: 1.15rem;
  color: #0e7275;
  padding: 0 0.5rem;
  margin-bottom: 25px;
  animation-name: fadeInUp;
}
form .btn-method {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 15px;
  border: 1px solid #ddd;
  flex-direction: column;
  background: #fff;
  width: calc(100% - 15px);
  color: #424242;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 4px;
  transition: all 0.15s;
}
form .btn-method img {
  max-height: 75px;
  mix-blend-mode: multiply;
}
form .btn-method span {
  display: block;
  line-height: 1.25rem;
}
form .btn-method img + span {
  padding-top: 10px;
}
form .btn-method:hover {
  border-color: #a490f6;
  background: #f1eefe;
  text-decoration: none;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}
form .btn-method.active {
  background: #f1eefe;
  border-color: #5632ee;
  color: #5632ee;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}
form .btn-method.active:hover {
  background: #ded7fc;
}
form .disabled .btn-method,
form .disabled .btn-method:hover {
  border-color: #eee !important;
  background: transparent !important;
  box-shadow: none !important;
}
form .form-row {
  transition: all 0.25s;
  animation-name: fadeInUp;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
form .form-row h4,
form .form-row h5 {
  margin-left: 0 !important;
}
form .form-row label {
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
}
form .form-row label.w-100 {
  font-weight: 400;
  color: #777;
  font-size: 0.7rem;
  margin-bottom: 5px;
}
form .form-row .label-inline {
  margin-bottom: 0;
  font-style: italic;
  color: #666;
  font-size: 0.85rem;
  font-weight: 400;
}
form .form-row .label-sm {
  margin-bottom: 10px;
  justify-content: flex-start;
  font-size: 0.65rem;
  color: #666;
}
form .form-row p {
  margin-bottom: 0;
  font-size: 0.9rem;
}
form .form-row .switch-wrapper {
  margin-left: auto;
  margin-top: -8px;
}
form .form-row .switch-wrapper:before {
  content: "Yes";
}
form .form-row .switch-wrapper:after {
  content: "No";
}
form .form-row .switch-wrapper .switch {
  height: 24px;
  width: 50px;
}
form .form-row .switch-wrapper .switch .slider:before {
  height: 16px;
  width: 16px;
}
form .form-row .form-group {
  width: 100%;
  margin-bottom: 0;
}
form .form-row .form-group .row {
  margin-left: 0;
  margin-right: 0;
}
form .form-row .form-group .row .col-sm-4:last-child .btn-method {
  width: 100%;
}
form .form-row .form-group .row .col-sm-8 .row {
  margin-right: -15px;
}
form .form-row .form-group .btn-link {
  font-size: 0.65rem;
  border-radius: 0;
  margin-top: 5px;
  font-weight: normal;
  color: #1898da;
}
form .form-row .form-group .btn-link i {
  margin-right: 3px;
  opacity: 0.65;
}
form .form-row .form-group .btn-link:hover {
  text-decoration: underline;
}
form .form-row .form-group .disabled .btn-method {
  color: #666;
  opacity: 0.45;
  font-style: italic;
  cursor: not-allowed;
}
form .form-row .form-group .disabled .btn-method:hover {
  background: #fff;
}
form .form-row .form-group .disabled .btn-link {
  color: #999;
  opacity: 0.65;
  cursor: not-allowed;
}
form .form-row .form-group .disabled .btn-link:hover {
  text-decoration: none;
}
form .form-row .input-group .form-control {
  border-radius: 4px !important;
  padding-left: 30px;
}
form .form-row .input-group .input-group-addon {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #e65b32;
  z-index: 10;
}
form .form-row .input-group-btn .form-control {
  padding-left: 15px;
}
form .form-row .input-group-btn .input-group-append {
  margin-left: 5px;
}
form .form-row .input-group-btn .input-group-append .btn {
  border-radius: 6px;
  padding-top: 0;
  padding-bottom: 0;
}
form .form-row .input-group-btn .input-group-append .btn.active {
  background: #1898da;
  border-color: #1898da;
  color: #fff;
}
form .form-row .input-group-btn .input-group-append .btn.active i {
  opacity: 1 !important;
}
form .form-row .date .form-control {
  border-radius: 4px !important;
  width: 100%;
}
form .form-row .date .input-group-addon {
  position: absolute;
  right: 10px;
  top: 7px;
}
form .form-row .date .input-group-addon span.zmdi {
  color: #e65b32;
}
form .form-row .form-control {
  border-radius: 6px;
}
form .form-row textarea {
  min-height: 90px;
}
form hr {
  animation-name: fadeInUp;
  animation-delay: 0.1s;
}
form .form-row:nth-child(1) {
  animation-delay: 0.05s;
}
form .form-row:nth-child(2) {
  animation-delay: 0.1s;
}
form .form-row:nth-child(3) {
  animation-delay: 0.15s;
}
form .form-row:nth-child(4) {
  animation-delay: 0.2s;
}
form .form-row:nth-child(5) {
  animation-delay: 0.25s;
}
form .form-row:nth-child(6) {
  animation-delay: 0.3s;
}
form .form-row:nth-child(7) {
  animation-delay: 0.35s;
}
form .form-row:nth-child(8) {
  animation-delay: 0.4s;
}
form .form-row:nth-child(9) {
  animation-delay: 0.45s;
}
form .form-row:nth-child(10) {
  animation-delay: 0.5s;
}
.filter-bar-wrapper {
  height: 68px;
  justify-content: flex-end
}
.offer-summary .form-row p {
  font-weight: 400;
}
.offer-summary .list-group {
  width: 75%;
  margin: 25px auto;
}
.offer-summary .list-group .list-group-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.offer-summary .list-group .list-group-item img {
  max-width: 100px;
  margin-right: 30px;
}
.offer-summary .list-group .list-group-item p {
  margin: 0;
  font-weight: 400;
  font-size: 0.9rem;
}
.offer-summary .list-group .list-group-item p strong {
  display: inline-block;
  padding: 0.2rem 0.25rem;
  text-align: center;
  font-weight: 800;
  border-radius: 6px;
  margin-right: 10px;
  border: 1px solid #ddd;
  background: #f6f6f6;
  min-width: 25px;
  line-height: 1rem;
  color: #1898da;
}
.offer-summary .list-group .list-group-item p.small {
  margin-left: auto;
  padding-right: 30px;
}
.offer-summary .list-group .list-group-item p.small label {
  font-weight: 400;
  color: #777;
  font-size: 0.7rem;
  margin-right: 8px;
  margin-bottom: 0;
  display: block;
}
.offer-summary .list-group .list-group-item p.small a {
  font-size: 0.75rem;
}

.create-panel {
  position: fixed;
  bottom: 0px;
  top: 100px;
  padding: 35px 50px;
  background: #fff;
  border-radius: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  right: 0;
  margin-right: -1000px;
  transition: all 0.5s;
  max-width: 30%;
}
.create-panel .create-panel-body {
  overflow-x: auto;
}
.create-panel .create-panel-body h4 {
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 15px;
  color: #525252;
}
.create-panel .create-panel-body .form-row {
  font-size: 0.9rem;
  padding: 10px 0;
  margin: 0;
}
.create-panel .create-panel-body .form-row label {
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.create-panel .create-panel-body .form-row .form-control {
  width: 100%;
  font-size: 0.8rem;
}
.create-panel .create-panel-body .form-button-row {
  display: flex;
  justify-content: space-between;
}
.create-panel .create-panel-body .form-button-row .btn {
  font-size: 0.8rem;
}

.create-panel.slideIn {
  margin-right: 0 !important;
}

.form-button-row {
  padding-top: 25px !important;
}
.form-button-row .btn {
  padding-left: 35px;
  padding-right: 35px;
}
.form-button-row .btn-primary {
  background: #1898da;
  color: #fff;
}
.form-button-row .btn-primary:hover {
  background: #106895;
}
.form-button-row .btn-outline-primary {
  border-color: #1898da;
  color: #1898da;
}
.form-button-row .btn-outline-primary:hover {
  background: #106895;
  border-color: #106895;
  color: #fff;
}

#marketingChannels {
  margin: 0 0 25px;
}
#marketingChannels .row {
  padding: 10px 0;
  max-width: 80%;
  border-bottom: 1px dotted #ddd;
  animation-name: fadeInUp;
}
#marketingChannels .row input[type="checkbox"] {
  vertical-align: middle;
  margin-right: 3px;
}
#marketingChannels .row .btn-outline-primary {
  width: auto;
  border: none;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
#marketingChannels .row .btn-outline-primary:hover {
  background: #fff;
  color: #0e7275;
}
#marketingChannels .row:last-child {
  border-bottom: none;
}
#marketingChannels .row:nth-child(1) {
  animation-delay: 0.05s;
}
#marketingChannels .row:nth-child(2) {
  animation-delay: 0.1s;
}
#marketingChannels .row:nth-child(3) {
  animation-delay: 0.15s;
}
#marketingChannels .row:nth-child(4) {
  animation-delay: 0.2s;
}
#marketingChannels .row:nth-child(5) {
  animation-delay: 0.25s;
}

.datepicker .datepicker-switch {
  color: #f0512d;
  transition: all 0.25s;
}
.datepicker .datepicker-switch:hover {
  background: #f5f5f5;
}
.datepicker th.prev,
.datepicker th.next {
  color: #f0512d;
  transition: all 0.25s;
}
.datepicker th.prev:hover,
.datepicker th.next:hover {
  background: #f5f5f5;
}
.datepicker table tr td.active.active {
  background: #5632ee !important;
}

.profile-card {
  padding: 25px 30px;
}
.profile-card hr {
  border-top-style: dotted;
  border-top-color: rgba(0, 0, 0, 0.15);
}
.profile-card .zmdi-facebook-box,
.profile-card .zmdi-instagram,
.profile-card .zmdi-twitter-box,
.profile-card .zmdi-linkedin-box,
.profile-card .zmdi-youtube {
  color: #1898da;
  font-size: 1.3rem;
  line-height: 1rem;
  opacity: 0.35;
  margin-right: 5px;
  vertical-align: middle;
}
.profile-card table.table td {
  font-size: 0.9rem;
}
.profile-card table.table td a {
  padding: 3px 10px;
  display: block;
  border: 1px solid #1898da;
  border-radius: 6px;
  font-size: 0.7rem;
  text-align: center;
}
.profile-card .btn-edit.collapsed:after {
  content: "Edit";
  font-family: "Inter", sans-serif;
  margin-left: 5px;
}
.profile-card .btn-edit.collapsed i {
  opacity: 1;
  display: inline-block;
}
.profile-card .btn-edit {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-right: -10px;
}
.profile-card .btn-edit:after {
  content: "Cancel";
  font-family: "Inter", sans-serif;
  margin-left: 5px;
}
.profile-card .btn-edit i {
  opacity: 0;
  display: none;
}
.profile-card .form-row {
  padding: 0.5rem 15px;
  font-size: 0.9rem;
  margin-left: 0;
  margin-right: 0;
}
.profile-card .form-row p {
  margin-bottom: 0;
}
.profile-card .form-row label {
  font-weight: bold;
  margin-right: 15px;
  margin-bottom: 5px;
  display: block;
  font-size: 0.7rem;
  width: 100%;
}
.profile-card .form-row ul {
  padding-left: 15px;
}
.profile-card .form-row ul li {
  font-size: 0.8rem;
}
.profile-card .form-row ul li label {
  display: inline-block;
  width: auto;
}
.profile-card .form-row ul li::marker {
  color: #7ac663;
}
.profile-card .form-row ul.programs-list {
  padding: 10px 5px;
  border: 1px solid #e0e0e0;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 10px;
}
.profile-card .form-row ul.programs-list li {
  font-size: 0.8rem;
  font-weight: bold;
}
.profile-card .form-row ul.programs-list li a {
  color: #222;
}
.profile-card .form-row ul.programs-list li .form-row {
  padding-left: 10px;
  padding-right: 10px;
}
.profile-card .form-row ul.programs-list li label {
  display: inline-block;
  width: auto;
  font-weight: normal;
  min-width: 135px;
}
.profile-card .form-row ul.programs-list li.list-header {
  padding: 0 10px;
}
.profile-card .form-row ul.programs-list li.list-header p {
  font-weight: 700;
  font-size: 0.7rem !important;
}
.profile-card .form-row ul.programs-list li.list-header a {
  font-size: 0.8rem;
  font-weight: 500;
}
.profile-card .form-row ul.programs-list li.list-header + li .form-row {
  padding-top: 5px !important;
}
.profile-card .form-row ul.list-checkbox {
  list-style: none;
  margin: 0;
  padding: 0;
}
.profile-card .form-row ul.list-checkbox li {
  list-style: none;
  font-size: 0.75rem;
  padding: 5px 0;
}
.profile-card .form-row ul.list-checkbox li input[type="checkbox"] {
  margin-right: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.profile-card .form-row ul.list-checkbox li label {
  display: inline-block;
  width: auto;
}
.profile-card .form-row .collapse,
.profile-card .form-row .collapsing {
  margin-top: 15px;
  width: 100% !important;
  padding: 25px;
  background: #f7f7f7;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}
.profile-card .form-row .collapse .form-row,
.profile-card .form-row .collapsing .form-row {
  max-width: 300px;
  margin: 0 auto;
}
.profile-card .form-row .collapse:before,
.profile-card .form-row .collapsing:before {
  content: "";
  display: block;
  clear: both;
}
.profile-card .form-row .collapse .btn-row,
.profile-card .form-row .collapsing .btn-row {
  max-width: 300px;
  margin: 0 auto;
  text-align: right;
}
.profile-card .form-row .collapse .btn-row .btn,
.profile-card .form-row .collapsing .btn-row .btn {
  margin-top: 15px;
}
.profile-card .lg-count-row {
  padding-right: 0 !important;
}
.profile-card .lg-count-row .lg-count:last-child {
  padding-left: 15px;
  padding-right: 0 !important;
  border-left: 1px dotted #ddd;
}
.profile-card .lg-count-row .lg-count:last-child a {
  color: #999;
}
.profile-card .lg-count-row .lg-count:last-child a:hover {
  color: #666;
}
.profile-card .lg-count-row .lg-count {
  padding-right: 15px;
  width: 50%;
}
.profile-card .lg-count-row .lg-count p,
.profile-card .lg-count-row .lg-count a {
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
  text-decoration: none;
  display: block;
}
.profile-card .lg-count-row .lg-count p:hover img,
.profile-card .lg-count-row .lg-count a:hover img {
  opacity: 0.75 !important;
}
.profile-card .lg-count-row .lg-count label {
  margin-right: 0;
  min-width: inherit !important;
  font-size: 0.65rem;
  font-weight: normal !important;
}
.profile-card .lg-count-row .lg-count img {
  width: 24px;
  opacity: 0.4;
  margin-right: 5px;
  transition: all 0.25s;
}
.profile-card .lg-count-row .lg-count .count-icon {
  text-align: center;
}

.social-card a {
  color: #222;
}

.container-lg {
  max-width: none;
}
.container-lg .navbar-brand img {
  max-width: 75px;
  height: 75px;
}
.container-lg .navbar .nav-item:nth-child(4) {
  margin-left: 0;
}

main #toggle-rail {
  display: block;
  height: 20px;
  width: 20px;
  background: #e65b32;
  color: #424242;
  text-align: center;
  line-height: 18px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 14.25%;
  top: 95px;
  z-index: 100;
  border: none;
  transition: all 0.2s;
}
main #toggle-rail i {
  vertical-align: middle;
}
main button#toggle-rail.active {
  left: 72px;
}
main button#toggle-rail.active i {
  transform: rotate(180deg);
}
main .rail-container {
  background-color: #414042;
  background-size: cover;
  padding: 105px 0 30px;
  margin-right: 60px;
  flex: 0 0 15%;
  max-width: 85%;
  position: fixed;
  transition: all 0.25s;
}
.rail-container #toggle-rail {
  display: block;
  height: 20px;
  width: 20px;
  background: #e65b32;
  color: #424242;
  text-align: center;
  line-height: 18px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  position: fixed;
  right: -10px;
  top: 78px;
  z-index: 100;
  border: none;
}
.rail-container #toggle-rail i {
  vertical-align: middle;
  transition: all 0.15s;
}
.rail-container .customer-logo {
  padding: 0 30px 0px;
  margin-top: 25px;
  min-height: 40px;
}
.rail-container .customer-logo img {
  max-height: 20px;
}
.rail-container .customer-logo .customer-logo-shrink {
  display: none;
}
.rail-container .section-title {
  padding: 0 30px 15px;
}
.rail-container .section-title h1 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
}
.rail-container .section-title .title-shrink {
  display: none;
}
.rail-container .nav-item:first-child.active a {
  border-bottom: none;
}
.rail-container .nav-submenu {
  padding: 0;
  background: rgba(0, 0, 0, 0.5);
  margin: 0;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
}
.rail-container .nav-submenu li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rail-container .nav-submenu li > a {
  display: block;
  padding: 12px 30px;
  color: #fff;
  opacity: 0.8;
  font-size: 0.8rem;
  margin-bottom: 0 !important;
}
.rail-container .nav-submenu li > a:hover {
  text-decoration: none;
  background: #504e50;
}
.rail-container .nav-submenu li > a.active {
  font-weight: 700 !important;
  letter-spacing: 0.5px;
  opacity: 1;
}
.rail-container button#toggle-rail.active i {
  transform: rotate(180deg);
}
.rail-container .nav {
  margin-top: 125px;
  transition: all 0.25s;
}
.rail-container .nav .nav-link {
  font-size: 0.8rem;
  color: #fff;
  padding: 20px 30px;
  transition: all 0.25s;
  letter-spacing: 1px;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
}
.rail-container .nav .nav-link i {
  opacity: 0.5;
  transition: all 0.15s;
  margin-right: 5px;
  font-size: 0.95rem;
  vertical-align: middle;
}
.rail-container .nav .nav-link span {
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.rail-container .nav .nav-link:hover {
  background: #292829;
}
.rail-container .nav .nav-link:hover i {
  opacity: 1;
  color: #7ac663;
}
.rail-container .nav .nav-item.a6-item .nav-link {
  background: #4e4d4f;
}
.rail-container .nav .nav-item.a6-item .nav-link:hover {
  background: #292829;
}
.rail-container .nav .nav-item.a6-item:last-child .nav-link {
  border-bottom: none;
}
.rail-container .nav .nav-link.active {
  background: #1898da;
  color: #fff;
  font-weight: bold;
}
.rail-container .nav .nav-link.active:hover i {
  color: #0b4867;
}
.rail-container .nav .dropdown-menu.show {
  position: relative !important;
  left: auto !important;
  right: auto !important;
  top: auto !important;
  bottom: auto !important;
  transform: none !important;
}
.rail-container .nav .dropdown-menu {
  background: #292829;
  border-radius: 0;
  min-width: inherit;
  padding: 0 !important;
  float: none;
}
.rail-container .nav .dropdown-menu > a {
  font-size: 0.8rem;
  color: #fff;
  transition: all 0.25s;
  padding-top: 15px;
  padding-bottom: 15px;
}
.rail-container .nav .dropdown-menu > a:hover {
  background: #7ac663;
}
main.shrink {
  flex: 0 0 80px;
  max-width: 80px;
}
main.shrink .nav-link span {
  width: 0;
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
  visibility: hidden;
  display: none;
}
main.shrink .nav-link i {
  font-size: 1.25rem;
}
main.shrink .section-title {
  padding: 0 15px 15px;
}
main.shrink .customer-logo-full,
main.shrink .title-full {
  display: none;
}
main.shrink .customer-logo-shrink,
main.shrink .title-shrink {
  display: block !important;
}
main #application-area {
  padding: 0;
  flex: 0 0 85%;
  max-width: 85%;
  transition: all 0.25s;
}
main #application-area .container {
  padding: 0 1rem;
}
main #application-area #platformContent {
  margin-top: 65px;
}
main #application-area .tab-content {
  margin-top: 45px;
}
main #application-area .tab-content .col-md-4 .card {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
main #application-area .tab-content .col-md-4:nth-child(2) .card {
  animation-delay: 0.1s;
}
main #application-area .tab-content .col-md-4:nth-child(3) .card {
  animation-delay: 0.15s;
}
main #application-area .tab-content .col-md-4:nth-child(4) .card {
  animation-delay: 0.2s;
}
main #application-area .col-sm-4 .profile-card {
  overflow: visible;
}
main #application-area .col-sm-4 .profile-card p {
  font-size: 0.8rem;
}
main #application-area .platform-navigation {
  position: fixed;
  right: 0;
  left: 15%;
  top: 54px;
  z-index: 10;
  transition: all 0.25s;
}
main #application-area .platform-navigation .btn {
  position: absolute;
  right: 50px;
  top: 10px;
  font-size: 0.75rem;
  padding-left: 20px;
  padding-right: 20px;
}
main #application-area .platform-navigation .btn i {
  margin-right: 5px;
  color: #1898da;
  opacity: 0.75;
}
main #application-area .platform-navigation .btn:hover {
  color: #aada9b;
  text-decoration: none;
}
main #application-area .platform-navigation .btn:hover i {
  opacity: 1;
}
main #application-area .user-card-header .user-avatar {
  margin-bottom: 10px;
  margin-top: -55px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
}
main #application-area .user-card-header .user-avatar img {
  max-height: 75px;
  border-radius: 50%;
  border: 3px solid #fff;
}
main #application-area .user-card-header h3 {
  padding-bottom: 5px;
}
main #application-area .user-card-header p.user-title {
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  color: #999;
}
main #application-area .user-card-header:after {
  content: "";
  display: block;
  width: 50px;
  height: 5px;
  background: #7ac663;
  margin: 15px 0;
  opacity: 0.5;
}
main #application-area.full-width {
  flex: 0 0 calc(100% - 80px);
  max-width: calc(100% - 80px);
}
main #application-area.full-width .platform-navigation {
  left: 80px;
}

.table-header .btn {
  font-size: 0.7rem;
}
.table-header .btn i {
  margin-right: 5px;
}
.table-header .table-sort {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-header .table-sort label {
  font-size: 0.7rem;
  font-style: italic;
  white-space: nowrap;
  padding-right: 10px;
  margin: 0 !important;
}
.table-header .table-sort select.form-control {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  height: auto;
}

#prizeList {
  border-top: 2px dotted #cabffa;
  margin-bottom: 0;
}
#prizeList th,
#prizeList td {
  border: none;
  vertical-align: middle;
}
#prizeList td label {
  margin-bottom: 5px;
  display: block !important;
  line-height: 1rem;
}
#prizeList td input[type="radio"] {
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}
#prizeList th {
  font-size: 0.8rem;
  padding-top: 1rem;
  padding-bottom: 0;
}
#prizeList tr:nth-child(even) td {
  border-bottom: 2px dotted #cabffa;
}

.panel {
  padding: 20px 25px 5px;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
}
.panel .btn {
  font-size: 0.8rem;
  margin-left: -5px;
}
.panel .btn i {
  margin-right: 5px;
}

#triggersTabContent h4 {
  font-size: 14px;
  margin-bottom: 15px;
  letter-spacing: 0.5px;
}
#triggersTabContent .trigger-header {
  margin-bottom: 0px;
  padding-bottom: 15px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 2px dotted #cabffa;
}
#triggersTabContent .trigger-header .custom-radio {
  height: 20px;
  width: 20px;
}
#triggersTabContent .trigger-header .custom-radio .checkmark {
  height: 20px;
  width: 20px;
}
#triggersTabContent .trigger-header .custom-radio .checkmark:after {
  height: 10px;
  width: 10px;
  left: 3px;
  top: 3px;
}
#triggersTabContent .trigger-header .custom-radio + span {
  font-size: 0.8rem;
  font-weight: bold;
}
#triggersTabContent form {
  padding: 0 2rem;
}
#triggersTabContent .form-row {
  margin: 0;
}
#triggersTabContent .form-row label {
  font-size: 0.8rem;
  width: 100%;
  margin-bottom: 5px;
}
#triggersTabContent .form-row p.help-text {
  font-size: 0.75rem;
  display: block;
  margin-bottom: 10px;
  width: 100%;
}

#offerSubmissions h4 {
  margin-left: -5px;
}
#offerSubmissions h5 {
  margin-bottom: 10px;
}
#offerSubmissions p.text-right {
  font-size: 0.7rem;
  vertical-align: middle;
}
#offerSubmissions p.text-right input[type="checkbox"] {
  margin-right: 3px;
  display: inline-block;
  vertical-align: middle;
}
#offerSubmissions .form-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#offerSubmissions .form-row.disabled {
  opacity: 0.35 !important;
  cursor: not-allowed;
}
#offerSubmissions .form-row.pt-4 {
  padding-top: 2rem !important;
}
#offerSubmissions .panel {
  padding: 20px 25px 15px;
  border: 1px solid #ddd;
  border-radius: 10px 10px 0 0;
}
#offerSubmissions .panel .form-row {
  margin: 0;
}
#offerSubmissions .panel + .panel {
  border-top: none;
  border-radius: 0 0 10px 10px;
}

.view-table {
  border-top: none;
}
.view-table thead th {
  font-size: 0.7rem;
  font-weight: 300;
  border-top: none;
  background: #fff !important;
}
.view-table td:first-child img {
  max-height: 20px;
  margin: 5px 0;
}
.view-table td {
  font-size: 0.8rem;
  vertical-align: middle;
}
.view-table td .btn {
  font-size: 0.7rem;
}
.view-table td .btn i {
  margin-right: 5px;
}
.view-table td .badge {
  border-radius: 15px;
  min-width: 75px;
  text-align: center;
}
.view-table tbody tr:nth-of-type(odd) {
  background: #f7f7f7;
}
.view-table tbody tr:last-child td {
  padding-top: 15px;
  padding-bottom: 15px;
}
.view-table tbody tr:last-child:hover {
  background: #fff !important;
}
.view-table td.table-actions a {
  display: inline-block;
  padding: 0 15px;
}
.view-table td.table-actions a:first-child {
  border-right: 1px dotted #ddd;
}

#designPreview {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 50px 0;
  background: #424242;
  border-radius: 6px;
  border: 1px solid #000;
}
#designPreview .phone-container {
  width: 300px;
  height: 610px;
  margin: 0 auto;
  background-size: contain !important;
  background: url(../../../assets/img/design-phonePlaceholder.png) no-repeat
    center;
  padding: 80px 43px 0;
}
#designPreview .phone-container .phone-hover {
  display: block;
  margin-left: -7px;
  border-radius: 6px;
  opacity: 0.65;
  transition: all 0.35s;
}
#designPreview .phone-container .phone-hover:hover {
  background: white;
}
#designPreview .phone-container phone-hover.active {
  background: white;
}
#designPreview .phone-container #phoneBrand {
  height: 50px;
}
#designPreview .phone-container #phoneContent {
  height: 165px;
  margin-top: 5px;
}
#designPreview .phone-container #phoneBrand-Button {
  height: 38px;
  margin-top: 102px;
}
#designPreview .phone-container img {
  width: 100%;
}

#designSections .accordion-card {
  overflow: visible;
}
#designSections .accordion-card .card-header {
  background: #fff;
  padding: 0;
}
#designSections .accordion-card .card-header .btn-link {
  padding: 0.75rem 1.15rem;
  width: 100%;
  text-align: left;
  border-radius: 0;
  color: #000;
  font-weight: 400;
}
#designSections .accordion-card .card-header i {
  font-size: 1.25rem;
  line-height: 1rem;
  vertical-align: middle;
  margin-right: 15px;
  color: #1898da;
}
#designSections .accordion-card .card-header .collapsed i.zmdi-minus:before {
  content: "\f278";
}
#designSections .accordion-card .card-body {
  padding-top: 0;
  background: #f7f7f7;
}
#designSections .accordion-card .card-body .form-control,
#designSections .accordion-card .card-body .emoji-wysiwyg-editor {
  position: relative;
}
#designSections .accordion-card .card-body .form-row {
  padding-top: 10px;
  padding-bottom: 10px;
}
#designSections .accordion-card .card-body textarea.form-control,
#designSections .accordion-card .card-body .emoji-wysiwyg-editor {
  min-height: 90px;
}

#customUpload {
  cursor: pointer;
  display: block;
  border: 1px dashed #1898da;
  color: #1898da;
  width: 100%;
  padding: 10px 25px;
  text-align: center;
  border-radius: 6px;
  transition: all 0.25s;
  background: #fff;
}
#customUpload i {
  margin-right: 5px;
  opacity: 0.5;
}
#customUpload input {
  display: none;
}
#customUpload #imageName {
  color: #424242;
  font-style: italic;
  padding-top: 5px;
}
#customUpload:hover {
  background: #dcf1fb;
}
.A6-col-wrapper {
  display: flex;
}

.A6-platform-wrapper {
  padding-top: 50px;
  width: 85%;
  max-width: 80%;
  position: relative;
  left: 20%;
  /* padding-bottom: 50px; */
  background: #f5f5f5;
  /* min-height: 800px;  */
}

.content-wrapper {
  margin-top: 20px;
}

.participant-list-large-width {
  max-width: 1400px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

form .form-row h4,
form .form-row h5 {
  margin-left: 0 !important;
}
form .form-row label {
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
}
form .form-row label.w-100 {
  font-weight: 400;
  color: #777;
  font-size: 0.7rem;
  margin-bottom: 5px;
}
form .form-row .label-inline {
  margin-bottom: 0;
  font-style: italic;
  color: #666;
  font-size: 0.85rem;
  font-weight: 400;
}
form .form-row .label-sm {
  margin-bottom: 10px;
  justify-content: flex-start;
  font-size: 0.65rem;
  color: #666;
}
form .form-row p {
  margin-bottom: 0;
  font-size: 0.9rem;
}
form .form-row .switch-wrapper {
  margin-left: auto;
  margin-top: -8px;
}
form .form-row .switch-wrapper:before {
  content: "Yes";
}
form .form-row .switch-wrapper:after {
  content: "No";
}
form .form-row .switch-wrapper .switch {
  height: 24px;
  width: 50px;
}
form .form-row .switch-wrapper .switch .slider:before {
  height: 16px;
  width: 16px;
}
form .form-row .form-group {
  width: 100%;
  margin-bottom: 0;
}
form .form-row .form-group .row {
  margin-left: 0;
  margin-right: 0;
}
form .form-row .form-group .row .col-sm-4:last-child .btn-method {
  width: 100%;
}
form .form-row .form-group .row .col-sm-8 .row {
  margin-right: -15px;
}
form .form-row .form-group .btn-link {
  font-size: 0.65rem;
  border-radius: 0;
  margin-top: 5px;
  font-weight: normal;
  color: #1898da;
}
form .form-row .form-group .btn-link i {
  margin-right: 3px;
  opacity: 0.65;
}
form .form-row .form-group .btn-link:hover {
  text-decoration: underline;
}
form .form-row .form-group .disabled .btn-method {
  color: #666;
  opacity: 0.45;
  font-style: italic;
  cursor: not-allowed;
}
form .form-row .form-group .disabled .btn-method:hover {
  background: #fff;
}
form .form-row .form-group .disabled .btn-link {
  color: #999;
  opacity: 0.65;
  cursor: not-allowed;
}
form .form-row .form-group .disabled .btn-link:hover {
  text-decoration: none;
}
form .form-row .input-group .form-control {
  border-radius: 4px !important;
  padding-left: 30px;
}
form .form-row .input-group .input-group-addon {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #e65b32;
  z-index: 10;
}
form .form-row .input-group-btn .form-control {
  padding-left: 15px;
}
form .form-row .input-group-btn .input-group-append {
  margin-left: 5px;
}
form .form-row .input-group-btn .input-group-append .btn {
  border-radius: 6px;
  padding-top: 0;
  padding-bottom: 0;
}
form .form-row .input-group-btn .input-group-append .btn.active {
  background: #1898da;
  border-color: #1898da;
  color: #fff;
}
form .form-row .input-group-btn .input-group-append .btn.active i {
  opacity: 1 !important;
}
form .form-row .date .form-control {
  border-radius: 4px !important;
  width: 100%;
}
form .form-row .date .input-group-addon {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-top: 7px;
  width: fit-content;
}
form .form-row .date .input-group-addon span.zmdi {
  color: #e65b32;
}
form .form-row .form-control {
  border-radius: 6px;
  height: 41.5px;
  padding: 10px 10px 10px 15px;
}
form .form-row textarea {
  min-height: 90px;
}
form hr {
  animation-name: fadeInUp;
  animation-delay: 0.1s;
}
form .form-row:nth-child(1) {
  animation-delay: 0.05s;
}
form .form-row:nth-child(2) {
  animation-delay: 0.1s;
}
form .form-row:nth-child(3) {
  animation-delay: 0.15s;
}
form .form-row:nth-child(4) {
  animation-delay: 0.2s;
}
form .form-row:nth-child(5) {
  animation-delay: 0.25s;
}
form .form-row:nth-child(6) {
  animation-delay: 0.3s;
}
form .form-row:nth-child(7) {
  animation-delay: 0.35s;
}
form .form-row:nth-child(8) {
  animation-delay: 0.4s;
}
form .form-row:nth-child(9) {
  animation-delay: 0.45s;
}
form .form-row:nth-child(10) {
  animation-delay: 0.5s;
}

.offer-summary .form-row p {
  font-weight: 400;
}
.offer-summary .list-group {
  width: 75%;
  margin: 25px auto;
}
.offer-summary .list-group .list-group-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.offer-summary .list-group .list-group-item img {
  max-width: 100px;
  margin-right: 30px;
}
.offer-summary .list-group .list-group-item p {
  margin: 0;
  font-weight: 400;
  font-size: 0.9rem;
}
.offer-summary .list-group .list-group-item p strong {
  display: inline-block;
  padding: 0.2rem 0.25rem;
  text-align: center;
  font-weight: 800;
  border-radius: 6px;
  margin-right: 10px;
  border: 1px solid #ddd;
  background: #f6f6f6;
  min-width: 25px;
  line-height: 1rem;
  color: #1898da;
}
.offer-summary .list-group .list-group-item p.small {
  margin-left: auto;
  padding-right: 30px;
}
.offer-summary .list-group .list-group-item p.small label {
  font-weight: 400;
  color: #777;
  font-size: 0.7rem;
  margin-right: 8px;
  margin-bottom: 0;
  display: block;
}
.offer-summary .list-group .list-group-item p.small a {
  font-size: 0.75rem;
}
.list-group-item.list-group-item.list-group-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-panel {
  position: fixed;
  bottom: 0px;
  top: 100px;
  padding: 35px 50px;
  background: #fff;
  border-radius: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  right: 0;
  margin-right: -1000px;
  transition: all 0.5s;
  max-width: 30%;
}
.create-panel .create-panel-body {
  overflow-x: auto;
}
.create-panel .create-panel-body h4 {
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 15px;
  color: #525252;
}
.create-panel .create-panel-body .form-row {
  font-size: 0.9rem;
  padding: 10px 0;
  margin: 0;
}
.create-panel .create-panel-body .form-row label {
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.create-panel .create-panel-body .form-row .form-control {
  width: 100%;
  font-size: 0.8rem;
}
.create-panel .create-panel-body .form-button-row {
  display: flex;
  justify-content: space-between;
}
.create-panel .create-panel-body .form-button-row .btn {
  font-size: 0.8rem;
}

.create-panel.slideIn {
  margin-right: 0 !important;
}

.form-button-row {
  padding-top: 25px !important;
}
.form-button-row .btn {
  padding-left: 35px;
  padding-right: 35px;
}
.form-button-row .btn-primary {
  background: #1898da;
  color: #fff;
}
.form-button-row .btn-primary:hover {
  background: #106895;
}
.form-button-row .btn-outline-primary {
  border-color: #1898da;
  color: #1898da;
}
.form-button-row .btn-outline-primary:hover {
  background: #106895;
  border-color: #106895;
  color: #fff;
}

#marketingChannels {
  margin: 0 0 25px;
}
#marketingChannels .row {
  padding: 10px 0;
  max-width: 80%;
  border-bottom: 1px dotted #ddd;
  animation-name: fadeInUp;
}
#marketingChannels .row input[type="checkbox"] {
  vertical-align: middle;
  margin-right: 3px;
}
#marketingChannels .row .btn-outline-primary {
  width: auto;
  border: none;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
#marketingChannels .row .btn-outline-primary:hover {
  background: #fff;
  color: #0e7275;
}
#marketingChannels .row:last-child {
  border-bottom: none;
}
#marketingChannels .row:nth-child(1) {
  animation-delay: 0.05s;
}
#marketingChannels .row:nth-child(2) {
  animation-delay: 0.1s;
}
#marketingChannels .row:nth-child(3) {
  animation-delay: 0.15s;
}
#marketingChannels .row:nth-child(4) {
  animation-delay: 0.2s;
}
#marketingChannels .row:nth-child(5) {
  animation-delay: 0.25s;
}

.datepicker .datepicker-switch {
  color: #f0512d;
  transition: all 0.25s;
}
.datepicker .datepicker-switch:hover {
  background: #f5f5f5;
}
.datepicker th.prev,
.datepicker th.next {
  color: #f0512d;
  transition: all 0.25s;
}
.datepicker th.prev:hover,
.datepicker th.next:hover {
  background: #f5f5f5;
}
.datepicker table tr td.active.active {
  background: #5632ee !important;
}

.profile-card {
  padding: 25px 30px;
}
.profile-card hr {
  border-top-style: dotted;
  border-top-color: rgba(0, 0, 0, 0.15);
}
.profile-card .zmdi-facebook-box,
.profile-card .zmdi-instagram,
.profile-card .zmdi-twitter-box,
.profile-card .zmdi-linkedin-box,
.profile-card .zmdi-youtube {
  color: #1898da;
  font-size: 1.3rem;
  line-height: 1rem;
  opacity: 0.35;
  margin-right: 5px;
  vertical-align: middle;
}
.profile-card table.table td {
  font-size: 0.9rem;
}
.profile-card table.table td a {
  padding: 3px 10px;
  display: block;
  border: 1px solid #1898da;
  border-radius: 6px;
  font-size: 0.7rem;
  text-align: center;
}
.profile-card .btn-edit.collapsed:after {
  content: "Edit";
  font-family: "Inter", sans-serif;
  margin-left: 5px;
}
.profile-card .btn-edit.collapsed i {
  opacity: 1;
  display: inline-block;
}
.profile-card .btn-edit {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-right: -10px;
}
.profile-card .btn-edit:after {
  content: "Cancel";
  font-family: "Inter", sans-serif;
  margin-left: 5px;
}
.profile-card .btn-edit i {
  opacity: 0;
  display: none;
}
.profile-card .form-row {
  padding: 0.5rem 15px;
  font-size: 0.9rem;
  margin-left: 0;
  margin-right: 0;
}
.profile-card .form-row p {
  margin-bottom: 0;
}
.profile-card .form-row label {
  font-weight: bold;
  margin-right: 15px;
  margin-bottom: 5px;
  display: block;
  font-size: 0.7rem;
  width: 100%;
}
.profile-card .form-row ul {
  padding-left: 15px;
}
.profile-card .form-row ul li {
  font-size: 0.8rem;
}
.profile-card .form-row ul li label {
  display: inline-block;
  width: auto;
}
.profile-card .form-row ul li::marker {
  color: #7ac663;
}
.profile-card .form-row ul.programs-list {
  padding: 10px 5px;
  border: 1px solid #e0e0e0;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 10px;
}
.profile-card .form-row ul.programs-list li {
  font-size: 0.8rem;
  font-weight: bold;
}
.profile-card .form-row ul.programs-list li a {
  color: #222;
}
.profile-card .form-row ul.programs-list li .form-row {
  padding-left: 10px;
  padding-right: 10px;
}
.profile-card .form-row ul.programs-list li label {
  display: inline-block;
  width: auto;
  font-weight: normal;
  min-width: 135px;
}
.profile-card .form-row ul.programs-list li.list-header {
  padding: 0 10px;
}
.profile-card .form-row ul.programs-list li.list-header p {
  font-weight: 700;
  font-size: 0.7rem !important;
}
.profile-card .form-row ul.programs-list li.list-header a {
  font-size: 0.8rem;
  font-weight: 500;
}
.profile-card .form-row ul.programs-list li.list-header + li .form-row {
  padding-top: 5px !important;
}
.profile-card .form-row ul.list-checkbox {
  list-style: none;
  margin: 0;
  padding: 0;
}
.profile-card .form-row ul.list-checkbox li {
  list-style: none;
  font-size: 0.75rem;
  padding: 5px 0;
}
.profile-card .form-row ul.list-checkbox li input[type="checkbox"] {
  margin-right: 5px;
  margin-bottom: 0;
  vertical-align: middle;
}
.profile-card .form-row ul.list-checkbox li label {
  display: inline-block;
  width: auto;
}
.profile-card .form-row .collapse,
.profile-card .form-row .collapsing {
  margin-top: 15px;
  width: 100% !important;
  padding: 25px;
  background: #f7f7f7;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}
.profile-card .form-row .collapse .form-row,
.profile-card .form-row .collapsing .form-row {
  max-width: 300px;
  margin: 0 auto;
}
.profile-card .form-row .collapse:before,
.profile-card .form-row .collapsing:before {
  content: "";
  display: block;
  clear: both;
}
.profile-card .form-row .collapse .btn-row,
.profile-card .form-row .collapsing .btn-row {
  max-width: 300px;
  margin: 0 auto;
  text-align: right;
}
.profile-card .form-row .collapse .btn-row .btn,
.profile-card .form-row .collapsing .btn-row .btn {
  margin-top: 15px;
}
.profile-card .lg-count-row {
  padding-right: 0 !important;
}
.profile-card .lg-count-row .lg-count:last-child {
  padding-left: 15px;
  padding-right: 0 !important;
  border-left: 1px dotted #ddd;
}
.profile-card .lg-count-row .lg-count:last-child a {
  color: #999;
}
.profile-card .lg-count-row .lg-count:last-child a:hover {
  color: #666;
}
.profile-card .lg-count-row .lg-count {
  padding-right: 15px;
  width: 50%;
}
.profile-card .lg-count-row .lg-count p,
.profile-card .lg-count-row .lg-count a {
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
  text-decoration: none;
  display: block;
}
.profile-card .lg-count-row .lg-count p:hover img,
.profile-card .lg-count-row .lg-count a:hover img {
  opacity: 0.75 !important;
}
.profile-card .lg-count-row .lg-count label {
  margin-right: 0;
  min-width: inherit !important;
  font-size: 0.65rem;
  font-weight: normal !important;
}
.profile-card .lg-count-row .lg-count img {
  width: 24px;
  opacity: 0.4;
  margin-right: 5px;
  transition: all 0.25s;
}
.profile-card .lg-count-row .lg-count .count-icon {
  text-align: center;
}

.social-card a {
  color: #222;
}

.container-lg {
  max-width: none;
}
.container-lg .navbar-brand img {
  max-width: 75px;
  height: 75px;
}
.container-lg .navbar .nav-item:nth-child(4) {
  margin-left: 0;
}

main #application-rail {
  background-color: #414042;
  background-size: cover;
  padding: 0;
  flex: 0 0 15%;
  max-width: 15%;
  position: relative;
  transition: all 0.25s;
}
main #application-rail #toggle-rail {
  display: block;
  height: 20px;
  width: 20px;
  background: #e65b32;
  color: #424242;
  text-align: center;
  line-height: 18px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 14.25%;
  top: 95px;
  z-index: 100;
  border: none;
  transition: all 0.2s;
}
main #application-rail #toggle-rail i {
  vertical-align: middle;
}
main #application-rail button#toggle-rail.active {
  left: 72px;
}
main #application-rail button#toggle-rail.active i {
  transform: rotate(180deg);
}
main #application-rail .rail-container {
  padding: 105px 0 30px;
  position: fixed;
  width: 100%;
}
main #application-rail .rail-container #toggle-rail {
  display: block;
  height: 20px;
  width: 20px;
  background: #e65b32;
  color: #424242;
  text-align: center;
  line-height: 18px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  position: fixed;
  right: -10px;
  top: 78px;
  z-index: 100;
  border: none;
}
main #application-rail .rail-container #toggle-rail i {
  vertical-align: middle;
  transition: all 0.15s;
}
main #application-rail .rail-container .customer-logo {
  padding: 0 30px 0px;
  min-height: 40px;
}
main #application-rail .rail-container .customer-logo img {
  max-height: 20px;
}
main #application-rail .rail-container .customer-logo .customer-logo-shrink {
  display: none;
}
main #application-rail .rail-container .section-title {
  padding: 0 30px 15px;
}
main #application-rail .rail-container .section-title h1 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
}
main #application-rail .rail-container .section-title .title-shrink {
  display: none;
}
main #application-rail .rail-container .nav-item:first-child.active a {
  border-bottom: none;
}
main #application-rail .rail-container .nav-submenu {
  padding: 15px 0 0;
  background: rgba(0, 0, 0, 0.5);
  margin: 0;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
}
main #application-rail .rail-container .nav-submenu li {
  list-style: none;
  margin: 0;
  padding: 0;
}
main #application-rail .rail-container .nav-submenu li > a {
  display: block;
  padding: 12px 30px;
  color: #fff;
  opacity: 0.8;
  font-size: 0.8rem;
  margin-bottom: 0 !important;
}
main #application-rail .rail-container .nav-submenu li > a:hover {
  text-decoration: none;
  background: #504e50;
}
main #application-rail .rail-container .nav-submenu li > a.active {
  font-weight: 700 !important;
  letter-spacing: 0.5px;
  opacity: 1;
}
main #application-rail .rail-container button#toggle-rail.active i {
  transform: rotate(180deg);
}
main #application-rail .rail-container .nav {
  transition: all 0.25s;
}
main #application-rail .rail-container .nav .nav-link {
  font-size: 0.8rem;
  color: #fff;
  padding: 20px 30px;
  transition: all 0.25s;
  letter-spacing: 1px;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
}
main #application-rail .rail-container .nav .nav-link i {
  opacity: 0.5;
  transition: all 0.15s;
  margin-right: 5px;
  font-size: 0.95rem;
  vertical-align: middle;
}
main #application-rail .rail-container .nav .nav-link span {
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
main #application-rail .rail-container .nav .nav-link:hover {
  background: #292829;
}
main #application-rail .rail-container .nav .nav-link:hover i {
  opacity: 1;
  color: #7ac663;
}
main #application-rail .rail-container .nav .nav-item.a6-item .nav-link {
  background: #4e4d4f;
}
main #application-rail .rail-container .nav .nav-item.a6-item .nav-link:hover {
  background: #292829;
}
main
  #application-rail
  .rail-container
  .nav
  .nav-item.a6-item:last-child
  .nav-link {
  border-bottom: none;
}
main #application-rail .rail-container .nav .nav-link.active {
  background: #1898da;
  color: #fff;
  font-weight: bold;
}
main #application-rail .rail-container .nav .nav-link.active:hover i {
  color: #0b4867;
}
main #application-rail .rail-container .nav .dropdown-menu.show {
  position: relative !important;
  left: auto !important;
  right: auto !important;
  top: auto !important;
  bottom: auto !important;
  transform: none !important;
}
main #application-rail .rail-container .nav .dropdown-menu {
  background: #292829;
  border-radius: 0;
  min-width: inherit;
  padding: 0 !important;
  float: none;
}
main #application-rail .rail-container .nav .dropdown-menu > a {
  font-size: 0.8rem;
  color: #fff;
  transition: all 0.25s;
  padding-top: 15px;
  padding-bottom: 15px;
}
main #application-rail .rail-container .nav .dropdown-menu > a:hover {
  background: #7ac663;
}
main #application-rail.shrink {
  flex: 0 0 80px;
  max-width: 80px;
}
main #application-rail.shrink .nav-link span {
  width: 0;
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
  visibility: hidden;
  display: none;
}
main #application-rail.shrink .nav-link i {
  font-size: 1.25rem;
}
main #application-rail.shrink .section-title {
  padding: 0 15px 15px;
}
main #application-rail.shrink .customer-logo-full,
main #application-rail.shrink .title-full {
  display: none;
}
main #application-rail.shrink .customer-logo-shrink,
main #application-rail.shrink .title-shrink {
  display: block !important;
}
main #application-area {
  padding: 0;
  flex: 0 0 85%;
  max-width: 85%;
  transition: all 0.25s;
}
main #application-area .container {
  padding: 0 1rem;
}
main #application-area #platformContent {
  margin-top: 65px;
}
main #application-area .tab-content {
  margin-top: 45px;
}
main #application-area .tab-content .col-md-4 .card {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
main #application-area .tab-content .col-md-4:nth-child(2) .card {
  animation-delay: 0.1s;
}
main #application-area .tab-content .col-md-4:nth-child(3) .card {
  animation-delay: 0.15s;
}
main #application-area .tab-content .col-md-4:nth-child(4) .card {
  animation-delay: 0.2s;
}
main #application-area .col-sm-4 .profile-card {
  overflow: visible;
}
main #application-area .col-sm-4 .profile-card p {
  font-size: 0.8rem;
}
main #application-area .platform-navigation {
  position: fixed;
  right: 0;
  left: 15%;
  top: 54px;
  z-index: 10;
  transition: all 0.25s;
}
main #application-area .platform-navigation .btn {
  position: absolute;
  right: 50px;
  top: 10px;
  font-size: 0.75rem;
  padding-left: 20px;
  padding-right: 20px;
}
main #application-area .platform-navigation .btn i {
  margin-right: 5px;
  color: #1898da;
  opacity: 0.75;
}
main #application-area .platform-navigation .btn:hover {
  color: #aada9b;
  text-decoration: none;
}
main #application-area .platform-navigation .btn:hover i {
  opacity: 1;
}
main #application-area .user-card-header .user-avatar {
  margin-bottom: 10px;
  margin-top: -55px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
}
main #application-area .user-card-header .user-avatar img {
  max-height: 75px;
  border-radius: 50%;
  border: 3px solid #fff;
}
main #application-area .user-card-header h3 {
  padding-bottom: 5px;
}
main #application-area .user-card-header p.user-title {
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  color: #999;
}
main #application-area .user-card-header:after {
  content: "";
  display: block;
  width: 50px;
  height: 5px;
  background: #7ac663;
  margin: 15px 0;
  opacity: 0.5;
}
main #application-area.full-width {
  flex: 0 0 calc(100% - 80px);
  max-width: calc(100% - 80px);
}
main #application-area.full-width .platform-navigation {
  left: 80px;
}

.table-header .btn {
  font-size: 0.7rem;
}
.table-header .btn i {
  margin-right: 5px;
}
.table-header .table-sort {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-header .table-sort label {
  font-size: 0.7rem;
  font-style: italic;
  white-space: nowrap;
  padding-right: 10px;
  margin: 0 !important;
}
.table-header .table-sort select.form-control {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  height: auto;
}

#prizeList {
  border-top: 2px dotted #cabffa;
  margin-bottom: 0;
}
#prizeList th,
#prizeList td {
  border: none;
  vertical-align: middle;
}
#prizeList td label {
  margin-bottom: 5px;
  display: block !important;
  line-height: 1rem;
}
#prizeList td input[type="radio"] {
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}
#prizeList th {
  font-size: 0.8rem;
  padding-top: 1rem;
  padding-bottom: 0;
}
#prizeList tr:nth-child(even) td {
  border-bottom: 2px dotted #cabffa;
}

.panel {
  padding: 20px 25px 5px;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
}
.panel .btn {
  font-size: 0.8rem;
  margin-left: -5px;
}
.panel .btn i {
  margin-right: 5px;
}

#triggersTabContent h4 {
  font-size: 14px;
  margin-bottom: 15px;
  letter-spacing: 0.5px;
}
#triggersTabContent .trigger-header {
  margin-bottom: 0px;
  padding-bottom: 15px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 2px dotted #cabffa;
}
#triggersTabContent .trigger-header .custom-radio {
  height: 20px;
  width: 20px;
}
#triggersTabContent .trigger-header .custom-radio .checkmark {
  height: 20px;
  width: 20px;
}
#triggersTabContent .trigger-header .custom-radio .checkmark:after {
  height: 10px;
  width: 10px;
  left: 3px;
  top: 3px;
}
#triggersTabContent .trigger-header .custom-radio + span {
  font-size: 0.8rem;
  font-weight: bold;
}
#triggersTabContent form {
  padding: 0 2rem;
}
#triggersTabContent .form-row {
  margin: 0;
}
#triggersTabContent .form-row label {
  font-size: 0.8rem;
  width: 100%;
  margin-bottom: 5px;
}
#triggersTabContent .form-row p.help-text {
  font-size: 0.75rem;
  display: block;
  margin-bottom: 10px;
  width: 100%;
}

#offerSubmissions h4 {
  margin-left: -5px;
}
#offerSubmissions h5 {
  margin-bottom: 10px;
}
#offerSubmissions p.text-right {
  font-size: 0.7rem;
  vertical-align: middle;
}
#offerSubmissions p.text-right input[type="checkbox"] {
  margin-right: 3px;
  display: inline-block;
  vertical-align: middle;
}
#offerSubmissions .form-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#offerSubmissions .form-row.disabled {
  opacity: 0.35 !important;
  cursor: not-allowed;
}
#offerSubmissions .form-row.pt-4 {
  padding-top: 2rem !important;
}
#offerSubmissions .panel {
  padding: 20px 25px 15px;
  border: 1px solid #ddd;
  border-radius: 10px 10px 0 0;
}
#offerSubmissions .panel .form-row {
  margin: 0;
}
#offerSubmissions .panel + .panel {
  border-top: none;
  border-radius: 0 0 10px 10px;
}

.view-table {
  border-top: none;
}
.view-table thead th {
  font-size: 0.7rem;
  font-weight: 300;
  border-top: none;
  background: #fff !important;
}
.view-table td:first-child img {
  max-height: 20px;
  margin: 5px 0;
}
.view-table td {
  font-size: 0.8rem;
  vertical-align: middle;
}
.view-table td .btn {
  font-size: 0.7rem;
}
.view-table td .btn i {
  margin-right: 5px;
}
.view-table td .badge {
  border-radius: 15px;
  min-width: 75px;
  text-align: center;
}
.view-table tbody tr:nth-of-type(odd) {
  background: #f7f7f7;
}
.view-table tbody tr:last-child td {
  padding-top: 15px;
  padding-bottom: 15px;
}
.view-table tbody tr:last-child:hover {
  background: #fff !important;
}
.view-table td.table-actions a {
  display: inline-block;
  padding: 0 15px;
}
.view-table td.table-actions a:first-child {
  border-right: 1px dotted #ddd;
}

.emoji-wysiwyg-editor {
  padding: 6px;
  padding-right: 35px;
  margin-bottom: 0px;
  min-height: 35px;
  height: 30px;
  max-height: 284px;
  overflow: auto;
  line-height: 17px;
  border: 1px solid #d2dbe3;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-user-select: text;
  word-wrap: break-word;
}

#designPreview {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 50px 0;
  background: #424242;
  border-radius: 6px;
  border: 1px solid #000;
}
#designPreview .phone-container {
  width: 300px;
  height: 610px;
  margin: 0 auto;
  background-size: contain !important;
  background: url(../../../assets/img/design-phonePlaceholder.png) no-repeat
    center;
  padding: 80px 43px 0;
}
#designPreview .phone-container .phone-hover {
  display: block;
  margin-left: -7px;
  border-radius: 6px;
  opacity: 0.65;
  transition: all 0.35s;
}
#designPreview .phone-container .phone-hover:hover {
  background: white;
}
#designPreview .phone-container phone-hover.active {
  background: white;
}
#designPreview .phone-container #phoneBrand {
  height: 50px;
}
#designPreview .phone-container #phoneContent {
  height: 165px;
  margin-top: 5px;
}
#designPreview .phone-container #phoneBrand-Button {
  height: 38px;
  margin-top: 102px;
}
#designPreview .phone-container img {
  width: 100%;
}

#designPreview .phone-container img.uploaded-logo {
  height: 90%;
  width: 42%;
  float: left;
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 4px;
}

#designPreview .phone-container img.uploaded-offer-image {
  height: 70%;
  width: 100%;
}

#designSections .accordion-card {
  overflow: visible;
}
#designSections .accordion-card .card-header {
  background: #fff;
  padding: 0;
}
#designSections .accordion-card .card-header .btn-link {
  padding: 0.75rem 1.15rem;
  width: 100%;
  text-align: left;
  border-radius: 0;
  color: #000;
  font-weight: 400;
}
#designSections .accordion-card .card-header i {
  font-size: 1.25rem;
  line-height: 1rem;
  vertical-align: middle;
  margin-right: 15px;
  color: #1898da;
}
#designSections .accordion-card .card-header .collapsed i.zmdi-minus:before {
  content: "\f278";
}
#designSections .accordion-card .card-body {
  padding-top: 0;
  background: #f7f7f7;
}
#designSections .accordion-card .card-body .form-control,
#designSections .accordion-card .card-body .emoji-wysiwyg-editor {
  position: relative;
}
#designSections .accordion-card .card-body .form-row {
  padding-top: 10px;
  padding-bottom: 10px;
}
#designSections .accordion-card .card-body textarea.form-control,
#designSections .accordion-card .card-body .emoji-wysiwyg-editor {
  min-height: 90px;
  /* min-width: 345px; */
}

#customUpload {
  cursor: pointer;
  display: block;
  border: 1px dashed #1898da;
  color: #1898da;
  width: 100%;
  padding: 10px 25px;
  text-align: center;
  border-radius: 6px;
  transition: all 0.25s;
  background: #fff;
}
#customUpload i {
  margin-right: 5px;
  opacity: 0.5;
}
#customUpload input {
  display: none;
}
#customUpload #imageOfferName {
  color: #424242;
  font-style: italic;
  padding-top: 5px;
}
#customUpload:hover {
  background: #dcf1fb;
}

#logoUpload {
  cursor: pointer;
  display: block;
  border: 1px dashed #1898da;
  color: #1898da;
  width: 100%;
  padding: 10px 25px;
  text-align: center;
  border-radius: 6px;
  transition: all 0.25s;
  background: #fff;
}
#logoUpload i {
  margin-right: 5px;
  opacity: 0.5;
}
#logoUpload input {
  display: none;
}
#logoUpload #imageName {
  color: #424242;
  font-style: italic;
  padding-top: 5px;
}
#logoUpload:hover {
  background: #dcf1fb;
}

.A6-platform-wrapper {
  padding-top: 62px;
  padding-bottom: 20px;
  background: #f5f5f5;
  min-height: 800px;
}
.A6-platform-wrapper .A6-filter-row {
  display: flex;
  flex-direction: row;
}
.A6-platform-wrapper .A6-filter-row select {
  max-width: 140px;
  margin-left: 15px;
  font-size: 0.7rem;
  border-radius: 30px;
}
.A6-platform-wrapper .A6-filter-row input.form-control {
  margin-left: auto;
  max-width: 150px;
  font-size: 0.7rem;
  border-radius: 20px;
}
.A6-platform-wrapper .A6-filter-row input.form-control::placeholder {
  color: #999;
  font-style: italic;
}
.A6-platform-wrapper .A6-filter-row .btn-group {
  margin-left: 15px;
}
.A6-platform-wrapper .A6-filter-row .btn {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 32px;
  line-height: 30px;
  border-color: #ccc;
  background: #fff;
}
.A6-platform-wrapper .A6-filter-row .btn i {
  color: #0e7275;
}
.A6-platform-wrapper .A6-filter-row .btn:hover {
  background: #7ac663;
}
.A6-platform-wrapper .A6-filter-row .btn:hover i {
  color: #fff;
}
.A6-platform-wrapper .A6-filter-row .btn.active {
  background: #0e7275;
}
.A6-platform-wrapper .A6-filter-row .btn.active i {
  color: #fff;
}
.A6-platform-wrapper h3 .switch-wrapper .switch {
  margin-top: -2px;
}
.A6-platform-wrapper h3 .switch-wrapper:before {
  content: "Active";
  display: inline-block;
  font-size: 12px;
  margin-right: 5px;
  vertical-align: middle;
  margin-top: 3px;
  font-weight: normal;
  color: #444;
}
.A6-platform-wrapper h3 .switch-wrapper:after {
  content: "Disabled";
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  margin-left: 5px;
  vertical-align: middle;
  margin-top: 3px;
  color: #444;
}
.A6-platform-wrapper h3 .switch-wrapper input:checked + .slider {
  background-color: #ccc;
}
.A6-platform-wrapper .form-control {
  font-size: 0.9rem;
}
.A6-platform-wrapper .tab-pane {
  padding-top: 20px;
}
.A6-platform-wrapper .card:not(.participant-list-table-wrapper):not(.individual-info-card):not(.basic-info-card) {
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}
.A6-platform-wrapper .card .customer-logo {
  width: auto;
  max-height: 25px;
  margin: 10px 5px;
}
.A6-platform-wrapper .card .card-avatar {
  max-width: 60px;
  margin-right: -10px;
  border: 2px solid #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  margin-bottom: -20px;
}
.A6-platform-wrapper .card .card-avatar.float-right {
  margin-top: -8px;
}
.A6-platform-wrapper .card .status-row {
  font-size: 10px;
  vertical-align: middle;
  margin-top: 13px;
  padding: 3px 15px;
  color: #777;
  font-style: italic;
  display: inline-block;
  border-radius: 15px;
  background: #f5f5f5;
}
.A6-platform-wrapper .card .status-row label {
  margin-right: 5px;
  margin-bottom: 0;
}
.A6-platform-wrapper .card .status-row .badge {
  width: 100%;
  max-width: 75px;
  margin-left: auto;
  height: 16px;
  line-height: 12px;
  font-size: 10px;
  display: block;
  margin-bottom: 5px;
  border-radius: 15px;
  vertical-align: middle;
}
.A6-platform-wrapper .card .card-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0 10px;
  margin-bottom: 5px;
  color: #424242;
  transition: all 0.25s;
}
.A6-platform-wrapper .card .card-count label {
  font-weight: 300;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0;
}
.A6-platform-wrapper .card .card-count .count-number {
  margin: 0;
  font-size: 32px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
}
.A6-platform-wrapper .card .card-count:hover {
  text-decoration: none;
  color: #7ac663;
  background: #f7f7f7;
}
.A6-platform-wrapper .card .card-name {
  font-size: 1rem;
  display: block;
  padding-top: 5px;
}
.A6-platform-wrapper .card .col-sm-6:first-child {
  padding-right: 7.5px;
}
.A6-platform-wrapper .card .col-sm-6:last-child .count-number {
  opacity: 0.35;
}
.A6-platform-wrapper .card.status-active {
  border-top-color: #92ea51;
}
.A6-platform-wrapper .card.status-active:before {
  content: "";
  height: 6px;
  display: block;
  background: #92ea51;
}
.A6-platform-wrapper .card.status-inactive {
  border-top-color: #bbb;
}
.A6-platform-wrapper .card.status-inactive:before {
  content: "";
  height: 6px;
  display: block;
  background: #bbb;
}
.A6-platform-wrapper #orgs .card .card-header {
  height: calc(195px - 47.8px);
}
.A6-platform-wrapper .data-card {
  overflow: auto;
  height: 100%;
}
.A6-platform-wrapper .data-card .card-header {
  height: auto !important;
  font-weight: 600;
}
.A6-platform-wrapper .data-card .card-header .col-sm-12 {
  padding-right: 30px !important;
}
.A6-platform-wrapper .data-card .card-header .card-more-link {
  position: absolute;
  right: 0;
  top: 0;
}
.A6-platform-wrapper .data-card .card-header .card-more-link .btn {
  padding: 0.15rem 0.75rem;
}
.A6-platform-wrapper .data-card .card-header .card-more-link .btn:after {
  display: none;
}
.A6-platform-wrapper .data-card .card-header .card-more-link .btn:hover {
  background: #f5f5f5;
}
.A6-platform-wrapper .data-card .card-header .card-more-link .dropdown-menu a {
  font-size: 0.75rem;
  padding: 0.35rem 1rem;
}
.A6-platform-wrapper
  .data-card
  .card-header
  .card-more-link
  .dropdown-menu
  a
  i {
  color: #999;
  opacity: 0.65;
  margin-right: 10px;
}
.A6-platform-wrapper
  .data-card
  .card-header
  .card-more-link
  .dropdown-menu
  a:hover {
  background: #f5f5f5;
}
.A6-platform-wrapper
  .data-card
  .card-header
  .card-more-link
  .dropdown-menu
  a:last-child
  i {
  color: #ea5151;
}
.A6-platform-wrapper .data-card .card-header .status-row {
  padding: 3px 10px;
  font-weight: normal;
}
.A6-platform-wrapper .data-card .card-body {
  padding-top: 15px;
  padding-bottom: 15px;
}
.A6-platform-wrapper .data-card .card-body .card-row {
  font-size: 0.75rem;
  padding: 2px 0;
}
.A6-platform-wrapper .data-card .card-body .card-row label {
  font-weight: 500;
  min-width: 130px;
}
.A6-platform-wrapper .data-card .card-body .card-row a,
.A6-platform-wrapper .data-card .card-body .card-row span {
  font-weight: 300;
}
.A6-platform-wrapper .data-card .card-body .col-sm-6 {
  padding: 0;
}
.A6-platform-wrapper .data-card .card-body .col-sm-6 .card-row {
  padding: 5px 0;
}
.A6-platform-wrapper .data-card .card-body .col-sm-6 .card-row label {
  display: block;
  min-width: inherit;
  margin: 0;
  font-weight: 400;
  font-size: 0.6rem;
  color: #888;
}
.A6-platform-wrapper .data-card .card-body .col-sm-6 .card-row a,
.A6-platform-wrapper .data-card .card-body .col-sm-6 .card-row span {
  font-weight: 500;
  font-size: 0.8rem;
}
.A6-platform-wrapper .data-card .card-body .col-sm-6:last-child {
  padding-left: 15px;
}
.A6-platform-wrapper .data-card .card-actions {
  margin-top: auto;
}
.A6-platform-wrapper .col-md-4 {
  /*
      .col-sm-6:last-child {
          padding-left:7.5px;
          .card-count {
              color:#424242;

              &:hover {
                  background:#eee;
              }
          }
      }*/
}
.A6-platform-wrapper .col-md-4 .card .form-row {
  font-size: 0.75rem;
}
.A6-platform-wrapper .col-md-4 .card .form-row label {
  width: 55px;
}
.A6-platform-wrapper .col-md-4 .card .form-row p {
  width: calc(100% - 55px);
}
.A6-platform-wrapper .col-md-4 .card-header {
  border: none;
  background: transparent;
  padding-bottom: 0;
}
.A6-platform-wrapper .col-md-4 .card-header .col-sm-6,
.A6-platform-wrapper .col-md-4 .card-header .col-sm-12 {
  padding: 0;
}
.A6-platform-wrapper .col-md-4 .card-actions {
  display: flex;
  flex-direction: row;
  background: #fff;
  border-top: 1px solid #eee;
  border-radius: 0 0 10px 10px;
  margin-top: auto;
}
.A6-platform-wrapper .col-md-4 .card-actions a {
  padding: 15px 12.5px;
  font-size: 0.7rem;
  transition: all 0.15s;
  color: #424242;
}
.A6-platform-wrapper .col-md-4 .card-actions a:hover {
  color: #999;
  text-decoration: none;
}
.A6-platform-wrapper .col-md-4 .card-actions .card-btn {
  width: 50%;
  text-align: center;
}
.A6-platform-wrapper .col-md-4 .card-actions .card-btn .badge-primary {
  border-radius: 50%;
  padding: 3px 5px !important;
  background: #cfebfa !important;
  color: #1898da;
  font-size: 0.65rem;
  font-weight: bold;
  margin-left: 3px;
}
.A6-platform-wrapper .col-md-4 .card-actions .card-btn:first-child {
  border-right: 1px solid #eee;
}
.A6-platform-wrapper .col-md-4 .card-actions .card-btn:hover {
  background: #f7f7f7;
}
.A6-platform-wrapper .col-md-4 .card-actions span.card-btn {
  text-align: center;
}
.A6-platform-wrapper .col-md-4 .card-actions span.card-btn .badge {
  display: block;
  max-width: 100px;
  border-radius: 0;
  padding: 0;
  margin: 10px auto 3px;
  text-transform: uppercase;
}
.A6-platform-wrapper .col-md-4 .card-actions span.card-btn .badge-success {
  background: transparent !important;
  color: #7ac663 !important;
  font-weight: 900;
}
.A6-platform-wrapper .col-md-4 .card-actions span.card-btn small {
  display: block;
  font-size: 0.55rem;
  font-style: italic;
}
.A6-platform-wrapper .col-md-4 .card-actions span.card-btn:hover {
  background: transparent;
}
.A6-platform-wrapper .col-md-4 .row {
  margin: 0;
}
.A6-platform-wrapper .card-create {
  padding: 20px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  transition: all 0.25s;
}
.A6-platform-wrapper .card-create:before {
  display: none !important;
}
.A6-platform-wrapper .card-create .card-icon {
  width: 100px;
  height: 100px;
  margin: 0 auto 5px;
  padding: 15px;
  border-radius: 50%;
  /*background:lighten($brand-green, 50%);*/
  transition: all 0.25s;
}
.A6-platform-wrapper .card-create img {
  width: 100%;
  opacity: 0.5;
  transition: all 0.25s;
}
.A6-platform-wrapper .card-create strong {
  font-weight: 300;
  font-size: 0.9rem;
  text-align: center;
  display: block;
  padding: 0px 3rem 0;
}
.A6-platform-wrapper .card-create:hover {
  border-color: #7ac663;
  background: #fff;
  color: #7ac663;
  text-decoration: none;
}
.A6-platform-wrapper .card-create:hover .card-icon {
  /*background:lighten($brand-green, 55%);*/
}
.A6-platform-wrapper .card-create:hover .card-icon img {
  opacity: 1;
}
.A6-platform-wrapper .card-create-alt {
  padding: 20px;
  color: #000;
  display: flex;
  background: transparent;
  flex-direction: column;
  justify-content: center;
  box-shadow: none;
  align-content: center;
  transition: all 0.25s;
}
.A6-platform-wrapper .card-create-alt:before {
  display: none !important;
}
.A6-platform-wrapper .card-create-alt .card-icon {
  width: 100px;
  margin: 0 auto 10px;
  text-align: center;
  border-radius: 50%;
  /*background:lighten($brand-green, 50%);*/
  transition: all 0.25s;
}
.A6-platform-wrapper .card-create-alt .card-icon i {
  font-size: 50px;
  color: #1898da;
  margin: 0 auto;
}
.A6-platform-wrapper .card-create-alt strong {
  font-weight: 300;
  font-size: 0.8rem;
  text-align: center;
  display: block;
  padding: 0px 3rem 0;
}
.A6-platform-wrapper .card-create-alt:hover {
  border-color: #7ac663;
  background: #fff;
  color: #1898da;
  text-decoration: none;
}
.A6-platform-wrapper .card-create-alt:hover .card-icon {
  /*background:lighten($brand-green, 55%);*/
}
.A6-platform-wrapper .card-create-alt:hover .card-icon img {
  opacity: 1;
}

.list-filter-row {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  align-items: center;
  width: 100%;
}
.list-filter-row label {
  font-size: 13px;
  font-weight: 500;
  min-width: 100px;
  margin: 0;
}
.list-filter-row ul {
  margin: 0;
  padding: 0;
  width: 100%;
}
.list-filter-row ul li {
  list-style: none;
  margin: 0 5px 0 0;
  padding: 6px 25px;
  border-radius: 6px;
  border: 1px solid #ddd;
  display: inline-block;
  font-size: 0.75rem;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;

  text-align: center;
}
.list-filter-row ul li:hover {
  background: #f4f4f4;
}
.list-filter-row ul li.active {
  background: linear-gradient(to right, #d832ee, #1764f4);
  color: #fff;
  border-color: #1764f4;
}

.col-sm-12 .list-filter-row label {
  min-width: 150px !important;
}

.list-filter-submit {
  display: block;
  text-align: right;
}
.list-filter-submit .btn {
  font-size: 0.8rem;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
}
.A6-platform-wrapper .A6-filter-row {
  display: flex;
  flex-direction: row;
}
.A6-platform-wrapper .A6-filter-row select {
  max-width: 140px;
  margin-left: 15px;
  font-size: 0.7rem;
  border-radius: 30px;
}
.A6-platform-wrapper .A6-filter-row input.form-control {
  margin-left: auto;
  max-width: 150px;
  font-size: 0.7rem;
  border-radius: 20px;
}
.A6-platform-wrapper .A6-filter-row input.form-control::placeholder {
  color: #999;
  font-style: italic;
}

.list-filter-row {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  align-items: center;
  width: 100%;
}
.list-filter-row label {
  font-size: 13px;
  font-weight: 500;
  min-width: 100px;
  margin: 0;
}
.list-filter-row ul {
  margin: 0;
  padding: 0;
  width: 100%;
}
.list-filter-row ul li {
  list-style: none;
  margin: 0 5px 0 0;
  padding: 6px 25px;
  border-radius: 6px;
  border: 1px solid #ddd;
  display: inline-block;
  font-size: 0.75rem;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  text-align: center;
}
.list-filter-row ul li:hover {
  background: #f4f4f4;
}
.list-filter-row ul li.active {
  background: linear-gradient(to right, #d832ee, #1764f4);
  color: #fff;
  border-color: #1764f4;
}

.col-sm-12 .list-filter-row label {
  min-width: 150px !important;
}

.list-filter-submit {
  display: block;
  text-align: right;
}
.list-filter-submit .btn {
  font-size: 0.8rem;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.A6-dashboard {
  background: #ebebeb;
}
.A6-dashboard > .container > .row {
  padding-bottom: 15px;
}
.A6-dashboard #card-arrow {
  background: linear-gradient(-45deg, #19f0e0, #1c88a2, #2965aa) !important;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
.A6-dashboard .row.equal-height .card {
  display: flex;
  height: 100%;
}
.A6-dashboard .card {
  min-height: inherit;
  padding: 15px;
  background: white;
  color: #222;
}
.A6-dashboard .card label {
  font-size: 0.75rem;
  font-weight: normal;
  margin-bottom: 5px;
  color: #9a9a9a;
}
.A6-dashboard .card .label-title {
  font-weight: 300;
  font-size: 12px;
  color: #999;
  font-weight: 300;
}
.A6-dashboard .card .card-body {
  padding: 0;
  min-height: 100px;
}
.A6-dashboard .card .card-graph {
  margin-top: 5px;
  background: #f6f6f6;
}
.A6-dashboard .card .card-graph img {
  width: 100%;
  height: auto;
}
.A6-dashboard .card p {
  font-size: 1.05rem;
  font-weight: 600;
  margin: 0;
}
.A6-dashboard .card .card-action {
  font-size: 0.65rem;
}
.A6-dashboard .card .card-icon {
  height: 18px;
  max-width: 26px;
  position: absolute;
  right: 15px;
  top: 10px;
}
.A6-dashboard .card .badge {
  display: block;
  width: auto;
  border-radius: 10px;
  max-width: 75px;
  position: absolute;
  right: 10px;
  top: 13.5px;
  color: #fff;
  font-size: 0.55rem;
  font-weight: bold;
  padding: 5px 8px 4px;
}
.A6-dashboard .card .badge-success {
  background: #69d51a;
}
.A6-dashboard .card .badge-warning {
  background: #d5b71a !important;
}
.A6-dashboard .card .badge-info {
  background: #067fb6;
  max-width: none;
}
.A6-dashboard .card small {
  font-size: 0.65rem;
  font-weight: 300;
  font-style: italic;
  opacity: 0.75;
}
.A6-dashboard .card #burn-chart {
  position: absolute;
  right: 20px;
  width: 28%;
  top: 37px;
}
.A6-dashboard .card #graph-arrow {
  width: 100%;
}
.A6-dashboard .card .tile-header {
  font-size: 13px;
  font-weight: 300;
  color: #7a7a7a;
  letter-spacing: 0px;
  padding-bottom: 10px;
  margin-bottom: 0px;
}
.A6-dashboard .card .container {
  padding: 0;
}
.A6-dashboard .hero-card .tile-header {
  min-height: 38px;
  margin-left: auto;
  padding: 0;
  text-align: right;
  padding-left: 20px;
}
.A6-dashboard .hero-card .col-md-3,
.A6-dashboard .hero-card .col-md-1-5 {
  padding: 0 !important;
  border-right: 1px dotted #ccc;
}
.A6-dashboard .hero-card .col-md-3:first-child .card-graph,
.A6-dashboard .hero-card .col-md-1-5:first-child .card-graph {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  overflow: hidden;
}
.A6-dashboard .hero-card .col-md-3:last-child,
.A6-dashboard .hero-card .col-md-1-5:last-child {
  border: none !important;
}
.A6-dashboard .hero-card .col-md-3:last-child .card-graph,
.A6-dashboard .hero-card .col-md-1-5:last-child .card-graph {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
}
.A6-dashboard .hero-card .card-icon {
  height: 30px;
  max-width: none;
  position: absolute;
  left: 10px;
  top: 5px;
}
.A6-dashboard .hero-card .card-body {
  padding: 0 20px;
  min-height: 110px;
  text-align: right;
}
.A6-dashboard .hero-card .card-body table {
  margin-left: auto;
}
.A6-dashboard .card-table-mini {
  font-size: 0.8rem;
  width: auto;
}
.A6-dashboard .card-table-mini tr {
  border-bottom: 1px dotted #ddd;
}
.A6-dashboard .card-table-mini tr td {
  padding: 5px 8px;
}
.A6-dashboard .card-table-mini tr td:first-child {
  font-weight: bold;
}
.A6-dashboard .card-table-mini tr td:last-child {
  padding-right: 0 !important;
}
.A6-dashboard .card-table-mini tr:last-child {
  border: none;
}
.A6-dashboard .card-table tr {
  border-bottom: 1px dotted #ddd;
}
.A6-dashboard .card-table tr th {
  font-size: 0.65rem;
  max-width: 100px;
}
.A6-dashboard .card-table tr th:first-child {
  max-width: none !important;
}
.A6-dashboard .card-table tr:hover td {
  background: #f7f7f7;
}
.A6-dashboard .card-table tr td {
  padding: 10px 5px;
  font-size: 0.7rem;
  transition: all 0.15s;
}
.A6-dashboard .card-table tr td:first-child {
  font-weight: 500;
}
.A6-dashboard .card-table tr:last-child {
  border: none;
}
.A6-dashboard .container > .row div[class*="col-"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
.A6-dashboard .container > .row .col-md-1-5 {
  width: 20%;
  position: relative;
}

.tab-card {
  overflow: visible !important;
  margin-top: 70px;
  border-top-left-radius: 0 !important;
}
.tab-card .nav-tabs {
  margin-top: -39px;
  border: none;
}
.tab-card .nav-tabs .nav-item .nav-link {
  border: none;
}
.tab-card .tab-content {
  padding: 15px 35px 0;
}

.account-card {
  padding: 5px 0 0;
}
.account-card .card-header {
  padding: 15px 0px 10px !important;
  margin: 0 25px 5px;
  background: #fff;
  border-bottom: none;
  border-radius: 4px 4px 0 0px;
}
.account-card .card-header p {
  font-size: 0.85rem;
  margin-bottom: 5px;
}
.account-card .card-header p:last-child {
  font-size: 0.6rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #666;
}
.account-card .card-header .user-avatar {
  margin-top: -10px;
  margin-right: -8px;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  float: right;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
}
.account-card .card-header .user-avatar img {
  max-height: 65px;
  border-radius: 50%;
  border: 2px solid #fff;
}
.account-card .card-header:after {
  content: "";
  display: block;
  width: 50px;
  height: 5px;
  background: #7ac663;
  margin: 15px 0 0;
  opacity: 0.5;
}
.account-card .form-row {
  margin: 0;
  padding: 0 25px;
  font-size: 0.7rem;
}
.account-card .form-row label {
  font-weight: 500;
  margin-right: 15px;
}
.account-card .form-row p {
  margin-left: auto;
}
.account-card .form-row .count-link {
  font-size: 0.75rem;
}
.account-card .form-row .count-link img {
  display: inline-block;
  width: 20px;
  height: auto;
  margin-right: 3px;
  opacity: 0.4;
  transition: all 0.25s;
}
.account-card .form-row .count-link:hover img {
  opacity: 0.8;
}
.account-card .card-actions {
  margin-top: 15px;
  display: flex;
  width: 100%;
  border-top: 1px solid #eee;
}
.account-card .card-actions .card-btn {
  width: 100% !important;
  border: none !important;
}
.account-card .card-actions a {
  padding: 15px 12.5px;
  font-size: 0.7rem;
  transition: all 0.15s;
  color: #424242;
}
.account-card .card-actions a:hover {
  color: #999;
  text-decoration: none;
}
.account-card .card-actions .card-btn {
  width: 100%;
  text-align: center;
  color: #424242;
}
.account-card .card-actions .card-btn:hover {
  background: #f7f7f7;
}
.account-card .count-link {
  color: #222;
}

.popover .popover-header {
  font-size: 0.85rem;
  background: transparent;
  border-bottom: none;
  padding-bottom: 0;
  padding-top: 15px !important;
  color: #1898da;
}
.popover .popover-body {
  padding-top: 10px !important;
  padding-bottom: 15px !important;
  font-size: 0.8rem;
}

.filter-card {
  width: 100%;
  padding: 0;
  height: 100%;
  min-height: inherit !important;
}
.filter-card h4.component-title {
  padding: 0 !important;
  margin-bottom: 0;
  background: #5632ee;
}
.filter-card h4 > a {
  color: #fff;
  padding: 15px 25px 15px;
}
.filter-card h4 > a i {
  float: right;
  font-size: 30px;
  line-height: 1rem;
  color: #fff;
  margin-right: 10px;
  transition: all 0.25s;
  vertical-align: middle;
}
.filter-card h4 > a.collapsed i {
  transform: rotate(180deg);
}
.filter-card .collapse,
.filter-card #filterCollapse {
  padding: 5px 25px 10px;
}
.filter-card .visualization-overlay span {
  display: block;
  text-align: center;
  font-size: 11px;
  letter-spacing: 0.5px;
  padding-bottom: 10px;
}

select.form-control option[value=""][disabled] {
  display: none;
  color: #999;
}

.table-card {
  padding: 20px;
  width: 100%;
}
.table-card .table-header {
  display: block;
}
.table-card .table-header p:first-child {
  margin: 0 12px 0px;
}
.table-card .table-actions {
  display: flex;
  padding: 10px 0px 8px;
  border-radius: 6px;
}
.table-card .table-actions label.custom-checkbox {
  margin-right: 15px !important;
}
.table-card .table-actions .table-actions-expand {
  opacity: 0;
  transition: opacity 0.25s;
  margin-top: -3px;
  display: none;
}
.table-card .table-actions .table-actions-expand.active {
  opacity: 1;
  display: block;
}
.table-card .table-actions .btn {
  background: #fff;
  opacity: 1;
  margin: 0 5px;
}
.table-card .table-actions .btn:hover {
  background: #1898da;
  color: #fff;
}
.table-card table th,
.table-card table td {
  vertical-align: middle;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.table-card table th label.custom-checkbox,
.table-card table td label.custom-checkbox {
  margin: 0 !important;
  height: 18px;
  display: block !important;
}
.table-card table th label.custom-checkbox .checkmark,
.table-card table td label.custom-checkbox .checkmark {
  height: 18px !important;
  width: 18px !important;
}
.table-card table th label.custom-checkbox .checkmark:after,
.table-card table td label.custom-checkbox .checkmark:after {
  left: 7px !important;
  top: 4px !important;
}
.table-card table th .custom-checkbox.selected .checkmark:after,
.table-card table td .custom-checkbox.selected .checkmark:after {
  left: 4px !important;
  top: 9px !important;
}
.table-card table th {
  font-size: 11px;
  border: none;
  background: #292829;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  white-space: nowrap;
}
.table-card table th:after {
  display: inline-block;
  margin-left: 5px;
  content: "\f2f9";
  vertical-align: middle;
  opacity: 0;
  transition: all 0.15s;
  font: normal normal normal 14px/1 "Material-Design-Iconic-Font";
}
.table-card table th:hover:after {
  opacity: 1;
}
.table-card table th:first-child:after {
  display: none !important;
}
.table-card table td {
  font-size: 12px;
}
.table-card table td .text-muted {
  color: #999 !important;
}
.table-card table td .zmdi {
  font-size: 15px;
  line-height: 1rem;
  vertical-align: middle;
}

.table-footer {
  padding: 5px;
}
.table-footer .pagination {
  margin: 0;
}
.table-footer .pagination .page-link {
  font-size: 0.8rem;
}
.table-footer .pagination .page-item.active .page-link {
  background: #1898da;
}
.table-footer .pagination .page-item.disabled .page-link {
  color: #999;
  background: #f6f6f6;
}

.simple-count-card {
  min-height: inherit !important;
}
.simple-count-card .card-count:hover {
  text-decoration: none;
  color: #5632ee !important;
  background: #fff !important;
}

.activity-card {
  padding: 20px;
}
.activity-card .input-group {
  padding: 5px 15px 20px 15px;
  border-radius: 10px 10px 0 0;
  border-bottom: 2px dotted #ddd;
}
.activity-card .input-group label {
  font-size: 0.8rem;
  padding-left: 0.35rem;
  padding-right: 2rem;
  background: transparent;
  border: none;
  font-weight: 500;
}
.activity-card .input-group select.form-control {
  font-size: 0.9rem;
  border-radius: 20px;
}
.activity-card .activity-feed {
  padding: 20px 15px;
}
.activity-card .activity-feed h2 {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
}
.activity-card .activity-feed ol {
  list-style: none;
  counter-reset: steps;
  position: relative;
  margin: 0;
  padding-left: 15px;
}
.activity-card .activity-feed ol li {
  counter-increment: steps;
  display: block;
  padding: 12px 0;
  font-size: 0.9rem;
  position: relative;
  z-index: 10;
}
.activity-card .activity-feed ol li::before {
  content: counter(steps);
  margin-right: 1rem;
  background: #7ac663;
  color: white;
  width: 1.8em;
  height: 1.8em;
  border-radius: 50%;
  display: inline-grid;
  place-items: center;
  line-height: 1.8em;
}
.activity-card .activity-feed ol li.incomplete:before {
  background: #ea5151;
}
.activity-card .activity-feed ol:before {
  content: "";
  height: calc(100% - 25px);
  top: 12px;
  z-index: 1;
  bottom: 10px;
  left: 1.7em;
  border-right: 3px solid #ececec;
  position: absolute;
}
.activity-card .media-item {
  padding: 8px;
  border: 1px solid #ddd;
  display: inline-block;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.activity-card .media-item img {
  max-width: 195px;
  border-radius: 4px;
}
.activity-card .media-item .media-body {
  padding-left: 25px;
}
.activity-card .media-item .media-body p {
  margin-bottom: 0;
  padding: 5px 0 10px;
  text-align: left;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
}
.activity-card .media-item .media-body label {
  margin-bottom: 0;
  min-width: 65px;
  font-weight: 600;
}

.bootstrap-datetimepicker-widget td {
  padding: 0 !important;
  width: auto !important;
}
.bootstrap-datetimepicker-widget a[data-action] {
  color: #f0512d;
  padding: 6px 0 !important;
}
.bootstrap-datetimepicker-widget a[data-action] span {
  color: #f0512d;
  font-size: 1.5rem;
}
.bootstrap-datetimepicker-widget .btn-primary {
  border-radius: 6px !important;
  font-size: 0.9rem !important;
  color: #f0512d;
  border: 1px solid #f0512d;
  background: transparent;
}
.bootstrap-datetimepicker-widget .btn-primary:hover {
  background: #f0512d;
  color: #fff;
}

.switch-wrapper:before {
  content: "Active";
  display: inline-block;
  font-size: 12px;
  margin-right: 5px;
  vertical-align: middle;
  margin-top: 3px;
}
.switch-wrapper:after {
  content: "All";
  display: inline-block;
  font-size: 12px;
  margin-left: 5px;
  vertical-align: middle;
  margin-top: 3px;
}

.modal .modal-footer,
.modal .modal-header {
  border: none;
}
.modal .modal-footer .btn,
.modal .modal-header .btn {
  font-size: 0.8rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  /*border-radius: 30px;*/
}
.modal .modal-header h5 {
  padding: 0 10px;
  font-size: 1.15rem;
  color: #0e7275;
}
.modal .form-row {
  padding: 1.15rem 10px;
  font-size: 0.9rem;
  margin-left: 0;
  margin-right: 0;
  border-bottom: 1px dotted #ddd;
}
.modal .form-row p {
  margin-bottom: 0;
}
.modal .form-row label {
  font-weight: bold;
  margin-right: 15px;
  margin-bottom: 5px;
  display: block;
  font-size: 0.7rem;
  width: 100%;
}
.modal .modal-body > p {
  padding: 0 10px;
}

.tooltip {
  font-size: 0.75rem;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
  margin: 0;
  /* Hide default HTML checkbox */
  /* The slider */
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input + .slider {
  /*background-color: $brand-green;*/
  background-image: linear-gradient(to right, #d832ee, #1764f4);
}
.switch input + .slider:focus + .slider {
  box-shadow: 0 0 1px #7ac663;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 30px;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  border-radius: 30px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch input:checked + .slider {
  background-color: #ccc;
  background-image: none;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #999;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}
.switch input:disabled + .slider {
  opacity: 0.25;
}

/*!
   * Timepicker Component for Twitter Bootstrap
   *
   * Copyright 2013 Joris de Wit
   *
   * Contributors https://github.com/jdewit/bootstrap-timepicker/graphs/contributors
   *
   * For the full copyright and license information, please view the LICENSE
   * file that was distributed with this source code.
   */
.bootstrap-timepicker {
  position: relative;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
  left: auto;
  right: 0;
}
.bootstrap-timepicker.pull-right
  .bootstrap-timepicker-widget.dropdown-menu:before {
  left: auto;
  right: 12px;
}
.bootstrap-timepicker.pull-right
  .bootstrap-timepicker-widget.dropdown-menu:after {
  left: auto;
  right: 13px;
}
.bootstrap-timepicker .input-group-addon {
  cursor: pointer;
}
.bootstrap-timepicker .input-group-addon i {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.bootstrap-timepicker-widget.dropdown-menu {
  padding: 4px;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
  display: inline-block;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
}
.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-left:after {
  left: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:before {
  right: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:after {
  right: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:before {
  top: -7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:after {
  top: -6px;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #ffffff;
}
.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget input {
  border-radius: 4px;
}
.bootstrap-timepicker-widget table {
  width: 100%;
  margin: 0;
}
.bootstrap-timepicker-widget table td {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 2px;
}
.bootstrap-timepicker-widget table td:not(.separator) {
  min-width: 30px;
}
.bootstrap-timepicker-widget table td span {
  width: 100%;
}
.bootstrap-timepicker-widget table td a {
  border: 1px transparent solid;
  width: 100%;
  display: inline-block;
  margin: 0;
  padding: 8px 0;
  outline: 0;
  color: #333;
}
.bootstrap-timepicker-widget table td a:hover {
  text-decoration: none;
  background-color: #eee;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-color: #ddd;
}
.bootstrap-timepicker-widget table td a i {
  margin-top: 2px;
  font-size: 18px;
}
.bootstrap-timepicker-widget table td input {
  width: 25px;
  margin: 0;
  text-align: center;
}

.bootstrap-timepicker-widget .modal-content {
  padding: 4px;
}

.progress-arrow {
  height: 0.5rem;
  margin: 7px 0;
}
.progress-arrow:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #5632ee;
  position: absolute;
  right: 2px;
  top: 1px;
}
.progress-arrow .progress-bar {
  background-color: #5632ee;
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  -ms-animation-direction: reverse;
  -o-animation-direction: reverse;
  animation-direction: reverse;
}

textarea {
  overflow: auto;
  resize: vertical;
}

textarea.form-control {
  height: auto;
}

.details-needed {
  text-align: left;
  padding-right: 0px;
  padding-left: 0px;
}

.details-switch {
  text-align: right;
}

.emoji-picker-container {
  width: 100%
}

@media (min-width: 1200px) {
  .A6-dashboard .container {
    max-width: 1180px;
  }
}
@media (min-width: 1600px) {
  .A6-dashboard .container {
    max-width: 1480px;
  }
  .A6-dashboard #burn-chart {
    width: 23% !important;
  }
}

@media(max-width: 992px){
  .A6-platform-wrapper {
    position: static;
    margin: auto;
    min-width: 100%;
  }
}

@media (min-width: 767px) {
  .bootstrap-timepicker-widget.modal {
    width: 200px;
    margin-left: -100px;
  }
}
@media (max-width: 767px) {
  .bootstrap-timepicker {
    width: 100%;
  }
  .bootstrap-timepicker .dropdown-menu {
    width: 100%;
  }
}
