.campaignWrapper{
  .navigationButtonsWrapper{
    padding-right: 200px;
  }
  .loader{
    background: rgba(255, 255, 255, 0.44);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    align-items: center;
    justify-content: center;
    display: none;
    &._active{
      display: flex;
    }
  }
  .alert{
    margin: 0;
    margin-top: 20px;
  }
}
.disableForm{
  background-color: rgba(0, 0, 0, 0.10);
  border-radius: 8px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  h3{
    border-radius: 8px 8px 0 0;
    background: #ffc1c1;
    text-align: center;
    padding: 14px;
    margin: 0;
  }
}

.navTopBtn {
  // border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
  padding: 4px 10px;
  margin: 0;
}

@media (min-width: 992px){
  .navTopBtn{
    margin: 0 -20px;
    height: 54px;
  }
}
.submitBox{
  // background-color: #FFFFFF;
  // border: 1px solid #ccc;
  //border-radius: 4px;
  //box-shadow: 0 0 3px 0px #ccc;
  //z-index: 10;
  //transition-duration: 300ms;
  //transition-property: transform;
  //display: flex;
  //padding: 10px;
  //overflow: visible;
  //position: absolute;
  //right: 20px;
  //top: 0;
  .submitBtn{
    margin: 6px 0 0 auto;
    padding: 10px;
    &:disabled{
      border-color: #1798D9;
      background-color: #53bef4;
      opacity: 1;
    }
    //&:hover{
    //  border-color: #1798D9;
    //  background-color: #1898da;
    //}
  }
}


.warning{}
.warningModal{
  .modal-content{
    padding: 10px 20px;
  }
  .modal-body{
    text-align: center;
    font-size: 16px;
  }
  .btn{
    border-radius: 4px;
  }
}
