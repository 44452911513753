.designerForm{
  padding: 0;
  margin: 0;
  .bootstrap-switch.margin0{
    margin: 0;
  }
}

.designerFormActionBox.designerFormActionBox.designerFormActionBox{
  display: flex;
  justify-content: right;
  padding-top: 10px;
  & button:last-child{
    margin-left: 10px;
  }
}
.designerFormActionButton.designerFormActionButton{
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 3px 12px;
  margin: 0;
  text-transform: none;
  position: relative;
}
.designerFormActionButton .designerFormActionButtonSpinner{
  position: absolute;
  right: 2px;
  top: 1px;
}
.designerFormActionButton.designerFormActionButton:first-child{
  background-color: #fff;
  color: #4a4747;
}
.designerFormActionButton.designerFormActionButton:first-child:hover{
  background-color: #fff;
}
.designerFormActionButton.designerFormActionButton:first-child:active{
  color: #4a4747;
}
.designerFormActionButton.designerFormActionButton:last-child{
  border: 1px solid #ec6612;
  background-color: #ec6612;
}
.designerFormActionButton.designerFormActionButton:hover{
  background-color: #ec6612;
}

.styled-select.styled-select{
  position: relative;
  .css-yk16xz-control{
    min-height: auto;
  }
  .css-g1d714-ValueContainer{
    padding: 2px 4px;
    padding-right: 0;
  }
  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer{
    padding: 0px;
    cursor: pointer;
  }
  .css-1hb7zxy-IndicatorsContainer{
    display: block;
  }
  .css-yk16xz-control{
    min-height: auto;
  }
  .css-tlfecz-indicatorContainer:last-child, .css-1gtu0rj-indicatorContainer:last-child{
    display: none!important;
  }
  .css-tlfecz-indicatorContainer:first-child, .css-1gtu0rj-indicatorContainer:first-child{
    &:hover{
      color: rgba(255, 0, 0, 0.51);
    }
  }
  .css-1rhbuit-multiValue{
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: #fff;
    line-height: 1;
    font-size: 13px;
    color: #5b5b5b;
    overflow: hidden;
    transition-duration: 0.3s;
    padding: 2px 4px;
    padding-right: 2px;
    margin: 2px;
    &:hover{
      transform: scale(1.15);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.3);
    }
    & > div:first-child{
      padding: 0;
      font-weight: 400;
    }
    & > div:last-child{
      border-radius: 50%;
      padding: 0;
      font-weight: 600;
      cursor: pointer;
      transition-duration: 0.3s;
      &:hover{
        background-color: transparent;
        transform: rotateZ(90deg);
      }
      svg{
        height: 12px;
        width: 12px;
      }
    }
  }
}
.cameraIcon{
  font-size: 30px;
  display: inline-block;
  line-height: 1;
  position: absolute;
  top: 10px;
  right: 20px;
  color: #ec6612;
  &.disable{
    &:after{
      content: '';
      background-color: #ec6612;
      position: absolute;
      left: 50%;
      bottom: -15%;
      width: 2px;
      height: 115%;
      transform: rotateZ(-47deg);
    }
  }
}
