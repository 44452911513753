hr {
  width: 100%;
  margin-left: 0;
}

.button.overview {
  margin: 0 !important;
  float: left;
  display: block;
  height: auto;
}

.prog-name {
  display: block;
  float: left;
  width: 93.5%;
}

.prim-contact {
  display: block;
  float: left;
  width: 93.5%;
}

.btnCancel {
  float: left;
}

.btnCreate {
  float: right;
}

.btnCancel,
.btnCreate {
  border-radius: 12px !important;
}

.progName {
  width: 100%;
}

.age-verif {
  padding-left: 1.25rem;
  float: left;
}

.age-ack,
.age-date,
.none {
  display: block;
  float: left;  
}

.img-ack,
.img-date {
  float: right;
  display: flex;
  display: block;
  justify-content: left;
  height: 50px;
  width: 50px;
  padding-right: 100px;
  padding-left: 100px;
}

#campSummary {
  resize: both;
  overflow: auto;
}
