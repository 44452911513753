.individual-info-card {
    border-radius: 10px;
    height: 120px;
    box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
    margin-bottom: 0px !important;
    .multi-col-wrapper p {
        word-break: break-word;
        font-size: 12px;
    }
}

  .svg-funnel-js {
    display: inline-block;
    position: relative;
    
  }
    .svg-funnel-js svg {
      display: block; 
      width: 100%;
      path: {
        width: 100% !important;
      }
    }
    .svg-funnel-js .svg-funnel-js__labels {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    .svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__labels {
      flex-direction: column; }

  .svg-funnel-js {
    font-family: "Open Sans", sans-serif; }
    .svg-funnel-js .svg-funnel-js__container {
      width: 100%;
      height: 100%; }
    .svg-funnel-js .svg-funnel-js__labels {
      width: 100%;
      box-sizing: border-box; }
      .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label {
        display: none;
        // flex: 1 1 0;
        // position: relative; 
      }
        .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
          // font-size: 24px;
          // color: black;
          // line-height: 18px;
          // margin-bottom: 6px; 
          // margin-top: 100%;
          display: none;
        }
        .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
          font-size: 12px;
          font-weight: bold;
          color: #21ffa2; }
        .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__percentage {
          font-size: 16px;
          font-weight: bold;
          color: #9896dc; }
        .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          left: 0;
          padding: 8px 24px;
          box-sizing: border-box;
          background-color: rgba(8, 7, 48, 0.8);
          margin-top: 24px;
          opacity: 0;
          transition: opacity 0.1s ease;
          cursor: default; }
          .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul {
            margin: 0;
            padding: 0;
            list-style-type: none; }
            .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul li {
              font-size: 13px;
              line-height: 16px;
              color: #fff;
              margin: 18px 0; }
              .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul li .percentage__list-label {
                font-weight: bold;
                color: #05df9d; }
        .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label:hover .label__segment-percentages {
          opacity: 1; }
    .svg-funnel-js:not(.svg-funnel-js--vertical) {
      padding-top: 64px;
      padding-bottom: 16px; }
      .svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label {
        padding-left: 24px; }
        .svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
          border-left: 1px solid #9896dc; }
    .svg-funnel-js.svg-funnel-js--vertical {
      padding-left: 120px;
      padding-right: 16px; }
      .svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label {
        padding-top: 24px; }
        .svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label:not(:first-child) {
          border-top: 1px solid #9896dc; }
        .svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label .label__segment-percentages {
          margin-top: 0;
          margin-left: 106px;
          width: calc(100% - 106px); }
          .svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label .label__segment-percentages .segment-percentage__list {
            display: flex;
            justify-content: space-around; }
    .svg-funnel-js .svg-funnel-js__subLabels {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      position: absolute;
      width: 100%;
      left: 0; }
      .svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel {
        display: flex;
        font-size: 12px;
        color: #fff;
        line-height: 16px; }
        .svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel:not(:first-child) {
          margin-left: 16px; }
        .svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel .svg-funnel-js__subLabel--color {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin: 2px 8px 2px 0; }


          .vertical-line {
            height: 95%;
            border-left: 1px dotted gray;
            z-index: 10 !important;
            position: absolute;
          }