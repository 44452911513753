.sectionBox{
  width: 960px;
  max-width: 100%;
  margin: 0 auto;
  &._mini{
    width: 720px;
  }
}
.userCardWrap.userCardWrap {
  width: 100%;
  max-width: 400px;
  min-width: 290px;

  .userCard.userCard.userCard.userCard.userCard {
    border-top: 8px solid #c3c3c3;
    position: relative;

    &._active {
      border-top: 8px solid greenyellow;
    }
  }

  .card-body {
    min-height: 140px;
    padding: 20px 30px;

    .cardHeader {
      position: relative;
    }

    .card-title {
      color: #23abf3;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 20px;
      margin-right: 40px;
    }

    .cardLogo {
      border: 2px solid #fff;
      border-radius: 50%;
      background-color: #f3f3f3;
      box-shadow: 0 2px 3px 0 #dcdbdb;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0;
      position: absolute;
      right: -20px;
      top: -15px;

      .cardLogoImg {
        max-height: 100%;
        max-width: 100%;
        font-size: 25px;
        color: #23abf3;
        font-weight: 600;
      }
    }

    .cardDesc {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: flex;
          margin-bottom: 8px;
          letter-spacing: 1px;

          &:last-child {
            margin-bottom: 0;
          }

          .cardDescTitle {
            width: 70px;
          }
        }
      }
    }
  }

  .cardFooter {
    border-top: 1px solid #ccc;
    display: flex;

    a {
      width: 50%;
      text-align: center;
      padding: 10px 0;
      text-decoration: none;
      // color: inherit;
      position: relative;
      overflow: hidden;
      font-weight: 600;
      color: #333;

      &:first-child {
        border-radius: 0 0 0 8px;
        border-right: 1px solid #ccc;
      }

      &:last-child {
        border-radius: 0 0 8px 0;
      }

      &:before {
        background-color: #0CBDF3FF;
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 2px;
        transition-property: width, left;
        transition-duration: 0.2s;
      }

      &:hover {
        &:before {
          width: 100%;
          left: 0;
        }
      }

      &:active {
        box-shadow: inset 0px 1px 1px 0px rgba(176, 176, 176, 0.5);

        &:before {
          height: 1px;
        }
      }
    }
    &.cardFooterNoEdit{
      a{
        width: 100%;
      }
    }
  }

  ._active {
    .cardFooter a {
      &:before {
        background: greenyellow;
      }
    }
  }
}

//////////userProfile
.userProfile {
  .profileHeader {
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    .__left {
      display: flex;
      align-items: center;
    }

    .__right {
      display: flex;
      font-size: 14px;
      align-items: center;
    }

    .profileLogo {
      border: 2px solid #fff;
      border-radius: 50%;
      background-color: #90d5d8;
      box-shadow: 0 2px 3px 0 rgba(118, 118, 118, 0.55);
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      margin-right: 20px;
      font-weight: bold;
      color: #fff;

      .profileLogoImg {
        max-height: 100%;
        max-width: 100%;
        font-size: 25px;
        color: #23abf3;
        font-weight: 600;
      }
    }

    .profileDesc {
      h6 {
        font-size: 20px;
        margin: 0;
      }

      span {
        text-transform: uppercase;
        font-size: 13px;
        color: #818181;
      }
    }

    .profileCreated {
      color: #818181;
    }

    .profileStatus {
      border: 1px solid rgba(42, 42, 42, 0.59);
      border-radius: 4px;
      background-color: #fff;
      padding: 0 8px;
      margin-left: 20px;
      margin-right: 20px;
      font-size: 12px;
      font-style: italic;
      position: relative;
      padding-left: 18px;

      &:before {
        border-radius: 50%;
        background-color: rgba(42, 42, 42, 0.59);
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        position: absolute;
        left: 7px;
        top: 6px;
      }

      &._active {
        border-color: greenyellow;

        &:before {
          background-color: greenyellow;
        }
      }
    }

    .profileMenu.profileMenu {
      .navbar {
        border: 1px solid #3db7f4;
        border-radius: 8px;
        background-color: transparent;
        min-height: auto;
        margin: 0;
        display: block;
        padding: 4px;

        .nav-link {
          margin: 0;
          padding: 0;
          line-height: 1;
          font-size: 20px;

          &:after {
            margin: 0;
            color: #000;
          }
        }

        .dropdown-menu {
          border-radius: 10px;
          background-color: #fff;
          color: #1b1a1b;
          font-weight: 600;
          overflow: hidden;

          .dropdown-item {
            color: #000;

            &:hover {
              background-color: #fff;
              color: #000 !important;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

///////profileBody
.profileBody {
  .card-body {

  }
  .profileDescription{
    padding: 0;
    list-style: none;
    width: 80%;
    margin: 0 auto;
    p, h6{
      margin: 0;
      font-size: 16px;
    }
    h6{
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 14px;
    }
    .cardDescPhone{
      letter-spacing: 1px;
    }
    li{
      border-bottom: 1px solid rgba(204, 204, 204, 0.56);
      padding: 20px 0;
      &:last-child{
        border-bottom: none;
      }
    }
  }
}
/////userForm
.userForm{
  .card{
    margin: 10px 0;
    padding: 20px;
    padding-top: 0;
  }
  .row{
    padding-bottom: 10px;
    &:last-child{
      padding-bottom: 0;
    }
  }
  .userFormFooter{
    display: flex;
    justify-content: space-between;
  }
  input{
    padding: 10px;
  }
  .rolesSection{
    border: 1px solid rgba(193, 193, 193, 0.73);
    border-radius: 4px;
    padding: 10px;
    h6{
      font-size: 14px;
    }
  }
}
.rolesSettings{
  padding-top: 20px;
  .table{
    border: 1px solid #c0c1c2;
    margin: 0;
  }
  .table .btn{
    padding: 8px 10px;
  }
  .rolesSettingsFooter{
    display: flex;
    justify-content: space-between;
    .btn{
      padding: 8px 10px;
      display: flex;
      align-items: center;
      svg{
        font-size: 18px;
      }
      span{
        padding-left: 6px;
      }
    }
  }
}
